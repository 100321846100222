// @flow

export const EMAIL = 'email'
export const ACCOUNT_TYPE = 'acc_type'
export const MARK_AS_READ = 'mark_as_read'
export const MARK_AS_READ_ALL = 'mark_read_by_all'
export const DISPLAY_NAME = 'email_from_name'
export const WORK_AS_EMAIL_CLIENT = 'work_as_email_client'
export const SIGNATURE = 'signature'
export const INSERT_SIGNATURE_BEFORE = 'insert_signature_before_quoted_text'
export const REPLY_TO = 'reply_to'
export const THUMBNAIL_SIZE = 510
export const CONVERSATION_VIEW = 'conversation_view'
export const AUTO_ARCHIVE_ON_ATTACH = 'archiving_message_in_request'

export const KEYS = [
  EMAIL,
  ACCOUNT_TYPE,
  SIGNATURE,
  INSERT_SIGNATURE_BEFORE,
  MARK_AS_READ,
  DISPLAY_NAME,
  MARK_AS_READ_ALL,
  REPLY_TO,
  WORK_AS_EMAIL_CLIENT,
  CONVERSATION_VIEW,
  AUTO_ARCHIVE_ON_ATTACH,
]

export const DEFAULT_VALUES: Object = {
  [EMAIL]: '',
  [ACCOUNT_TYPE]: '',
  [SIGNATURE]: '',
  [INSERT_SIGNATURE_BEFORE]: false,
  [AUTO_ARCHIVE_ON_ATTACH]: false,
  [MARK_AS_READ]: false,
  [DISPLAY_NAME]: '',
  [REPLY_TO]: '',
  [WORK_AS_EMAIL_CLIENT]: false,
  [CONVERSATION_VIEW]: true,
}

export const SIG_IMAGE_MAX_SIZE = 2.5
