const FLATS = 'flats_list'
const REQUESTS = 'requests_list'
const BUILDINGS = 'buildings_list'
const CONTRACTORS = 'contractors_list'
const ACTIVITIES = 'activities_list'
const INHABITANTS = 'inhabitants_list'
const EMAILS = 'email_threads_list'
const NEWSBOARD = 'newsboard'

export const redesignLinksMatch = {
  [FLATS]: '/flats',
  [REQUESTS]: '/requests',
  [BUILDINGS]: '/buildings',
  [CONTRACTORS]: '/contractors',
  [ACTIVITIES]: '/activities',
  [INHABITANTS]: '/users/',
  [EMAILS]: '/mails',
  [NEWSBOARD]: '/',
}
