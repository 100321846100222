// @flow

import React from 'react'
import { useEffect, useState } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import Button from '../../../../../components/Button'
import Modal from '../../../../../components/Modal'
import ModalBody from '../../../../../components/Modal/ModalBody'
import ModalCloseButton from '../../../../../components/Modal/ModalCloseButton'
import ModalHead from '../../../../../components/Modal/ModalHead'
import ModalButtons from '../../../../../components/Modal/ModalButtons'
import NewTable from '../../../../../components/NewTable'
import NewTableHeader from '../../../../../components/NewTable/NewTableHeader'
import NewTableHeaderRow from '../../../../../components/NewTable/NewTableHeaderRow'
import NewTableHeaderCell from '../../../../../components/NewTable/NewTableHeaderCell'
import NewTableBody from '../../../../../components/NewTable/NewTableBody'
import NewTableBodyRow from '../../../../../components/NewTable/NewTableBodyRow'
import NewTableBodyCell from '../../../../../components/NewTable/NewTableBodyCell'
import Checkbox from '../../../../../components/Checkbox'
import { useOverflow, useSelected } from '../../../../../hooks'
import {
  getArchivedPipelines,
  restorePipeline,
} from '../../../../../core/api/api.pipeline'
import { getFormattedDate } from '../../../../../utils/utils'
import Text from '../../../../../components/NewTable/NewTableHeaderCell/Text'
import ConfirmationPopup from '../../../../../components/modals/ConfirmationPopup'

const PipelinesModal = (props): Node => {
  const { requestId } = props

  const [pipelines, setPipelines] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [isConfirmOpen, setConfirmOpen] = useState(false)

  const { t } = useTranslation('Activities')

  useOverflow()

  const [selectedItems, changeSelected, setSelected] = useSelected()

  useEffect(() => {
    getArchivedPipelines(requestId).then(data => {
      setPipelines(data)
      setLoading(false)
    })
  }, [])

  const handleChange = e => {
    changeSelected(parseInt(e.currentTarget.value))
  }

  const handleCancel = () => {
    if (selectedItems.length) {
      setSelected([])
    } else {
      props.onClose()
    }
  }

  const handleRestore = () => {
    setLoading(true)

    restorePipeline(requestId, { pipeline: selectedItems[0] }).then(() => {
      props.onRestore()
      props.onClose()
    })
  }

  const tableClass = classnames({
    'working-overlay': isLoading,
  })

  const handleCloseModal = () => {
    setConfirmOpen(false)
  }

  const handleOpenConfirm = () => {
    setConfirmOpen(true)
  }

  return (
    <Modal
      isOpen
      style={{
        content: { minWidth: '732px', maxWidth: '732px' },
      }}
    >
      <ModalHead title={t('PipelinesTitle')} />
      <ModalCloseButton onClose={props.onClose} />
      <ModalBody style={{ maxHeight: '604px', overflow: 'auto' }}>
        <NewTable className={tableClass}>
          <NewTableHeader>
            <NewTableHeaderRow>
              <NewTableHeaderCell style={{ width: '25%' }}>
                <Text title={t('PipelineTitle')} />
              </NewTableHeaderCell>
              <NewTableHeaderCell
                style={{ width: '25%' }}
                title={t('PipelineCategory')}
              />
              <NewTableHeaderCell
                style={{ width: '25%' }}
                title={t('PipelineCreated')}
              />
              <NewTableHeaderCell
                style={{ width: '25%' }}
                title={t('PipelineArchived')}
              />
            </NewTableHeaderRow>
          </NewTableHeader>
          <NewTableBody>
            {pipelines.map(pipeline => (
              <NewTableBodyRow key={pipeline.id}>
                <NewTableBodyCell title={pipeline.name}>
                  <Checkbox
                    value={pipeline.id}
                    checked={selectedItems.includes(pipeline.id)}
                    style={{ marginLeft: '12px' }}
                    onChange={handleChange}
                  />
                </NewTableBodyCell>
                <NewTableBodyCell title={pipeline.category} />
                <NewTableBodyCell title={getFormattedDate(pipeline.created)} />
                <NewTableBodyCell title={getFormattedDate(pipeline.updated)} />
              </NewTableBodyRow>
            ))}
          </NewTableBody>
        </NewTable>
      </ModalBody>
      <ModalButtons>
        <Button.Save
          disabled={selectedItems.length !== 1 || isLoading}
          onClick={handleOpenConfirm}
        >
          {t('Restore')}
        </Button.Save>
        <Button.Cancel disabled={isLoading} onClick={handleCancel}>
          {t('Common:Cancel')}
        </Button.Cancel>
      </ModalButtons>
      {isConfirmOpen && (
        <Modal isOpen>
          <ConfirmationPopup
            confirm={t('Confirm')}
            title={t('PipelineRestoreTitle')}
            text={t('PipelineRestoreText')}
            onClose={handleCloseModal}
            onOk={handleRestore}
          />
        </Modal>
      )}
    </Modal>
  )
}

export default PipelinesModal
