// @flow

import React from 'react'
import classnames from 'classnames'
import type { Node } from 'react'

import styles from './Copy.module.scss'

import Icon from '../../../../static/icons/copy.svg'

type Props = {
  className?: string,
  disabled: boolean,
  name?: string,
  onClick: () => void,
  style?: Object,
  title: string,
}

const Copy = (props: Props): Node => {
  const { className, disabled, name, style, title } = props

  const buttonProps = Object.assign(
    {},
    disabled ? { disabled: true } : undefined,
    name ? { name } : undefined,
    style ? { style } : undefined,
    props.onClick ? { onClick: props.onClick } : undefined
  )

  const buttonClass = classnames([styles.add], className)

  return (
    <button type='button' className={buttonClass} {...buttonProps}>
      <Icon className={styles.icon} />
      {title}
    </button>
  )
}

Copy.defaultProps = {
  disabled: false,
}

export default Copy
