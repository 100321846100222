// @flow

import React, { useEffect, useState } from 'react'
import { get, partial, omitBy, isString } from 'lodash-es'
import classNames from 'classnames'
import { useFormik } from 'formik'
import Textarea from 'react-textarea-autosize'
import Scrollbar from 'react-scrollbars-custom'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import ScrollbarTrackY from './ScrollbarTrackY'
import ScrollbarWrapper from './ScrollbarWrapper'
import ThumbY from '../../NewFilterAsync/ThumbY'
import { getRequestOptions } from '../../../core/api/api.request'
import { useOverflow, useUser, useRequestTitle } from '../../../hooks'
import InputField from '../../InputField'
import Modal from '../../Modal'
import ModalBody from '../../Modal/ModalBody'
import ModalCloseButton from '../../Modal/ModalCloseButton'
import ModalHead from '../../Modal/ModalHead'
import ModalRow from '../../Modal/ModalRow'
import NewSelectAsync from '../../NewSelectAsync'
import Button from '../../Button'
import ModalButtons from '../../Modal/ModalButtons/ModalButtons'
import {
  createActivity,
  createActivityTemplate,
  getRequestPipeline,
  updateActivity,
  updateActivityTemplate,
} from '../../../core/api/api.pipeline'
import ActivityModalType from './ActivityModalType'
import NewSelectSimple from '../../NewSelectSimple'
import ActivityModalLabel from './ActivityModalLabel'
import ActivityModalRow from './ActivityModalRow'
import {
  CONTAINED_VIEW,
  OUTLINED_VIEW,
} from '../../Button/Regular/Regular.constants'
import api from '../../../core/api'
import { formatDate, getChangedValues, getUserName } from '../../../utils/utils'
import ActivityModalDeadline from './ActivityModalDeadline'
import {
  isTemplateText,
  getInitialValues,
  getPhaseValue,
  getRequestValue,
  getManagerValue,
  getPriorityValue,
  getValidationSchema,
  getNormalizedValues,
  timeChanged,
} from './ActivityModal.utils'
import {
  BUILDING,
  COMMIT_TYPE,
  DEADLINE_DATE,
  DEADLINE_TEMPLATE,
  DEADLINE_TIME,
  DONE_COMMIT_TYPE,
  MANAGER,
  NOTES,
  PHASE,
  PRIORITY,
  REQUEST,
  TITLE,
  TYPE,
  YESNO_COMMIT_TYPE,
} from './ActivityModal.constants'
import { ACTIVE_STATUS, FORMIK_EMPTY_VALUE } from '../../../constants'
import ActivityModalPriority from './ActivityModalPriority'
import ModalLabel from '../../Modal/ModalLabel'

import styles from './ActivityModal.module.scss'
import { globalModalError } from '../../Layout/Layout.actions'
import { useDispatch } from 'react-redux'

type Props = {
  activity?: ?Object,
  activityPriorities: Array<Object>,
  activityTypes: Array<Object>,
  building?: Object,
  isTemplate?: boolean,
  load: Function,
  onClose: Function,
  phaseTemplateId?: number,
  request?: Object,
  setActivity?: (?Object) => void,
  setActivityPriorities: (?Array<Object>) => void,
  setActivityTypes: (?Array<Object>) => void,
  setModalOpen: boolean => void,
  setNewPhaseId?: number => void,
  setPhaseId?: number => void,
}

const ActivityModal = (props: Props): Node => {
  const {
    activity,
    request,
    activityPriorities,
    activityTypes,
    building,
    isTemplate,
    phaseTemplateId,
    phaseId,
    setPhaseId,
    setNewPhaseId,
  } = props

  const { t } = useTranslation('Activities')
  const getRequestTitle = useRequestTitle()
  const user = useUser()

  useOverflow()

  const [phaseList, setPhaseList] = useState(null)

  const modalTitle = activity
    ? t('EditActivityTitle')
    : t('CreateActivityTitle')

  const modalSaveButton = activity
    ? t('EditActivityButton')
    : t('CreateActivityButton')

  const dispatch = useDispatch()

  const [isInstanceOpen, setInstanceOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const getManagerSelectedLabel = opt => opt.name

  useEffect(() => {
    return function cleanup() {
      if (props.setActivity) {
        props.setActivity(null)
      }

      props.setActivityPriorities(null)
      props.setActivityTypes(null)
    }
  }, [])

  const formik = useFormik({
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: getInitialValues(
      activity,
      request,
      building,
      activityTypes,
      user
    ),
    validationSchema: getValidationSchema(isTemplate, t, phaseList),
    onSubmit: submitValues => {
      let values = {}

      if (!activity) {
        values = omitBy(submitValues, value => value === FORMIK_EMPTY_VALUE)
      } else {
        values = timeChanged(
          getChangedValues(formik.initialValues, submitValues),
          formik.initialValues
        )
      }

      const normalizedValues = getNormalizedValues(
        values,
        activity,
        isTemplate,
        phaseTemplateId
      )

      const updateApi = isTemplate ? updateActivityTemplate : updateActivity
      const createApi = isTemplate ? createActivityTemplate : createActivity

      const api = activity ? partial(updateApi, activity.id) : createApi

      api(normalizedValues)
        .then(() => {
          if (
            phaseId &&
            formik.values[PHASE].value !== phaseId &&
            setNewPhaseId
          ) {
            setNewPhaseId(formik.values[PHASE].value)
          } else if (!phaseId && setPhaseId) {
            setPhaseId(formik.values[PHASE].value)
          }

          props.load(true)
          props.setModalOpen(false)
        })
        .catch(error => {
          props.setModalOpen(false)
          const err = get(error, ['message', 'response', 'data', 'errors'], {})

          const errorText = isString(err) ? err : Object.values(err)?.[0]

          const action: Object = globalModalError(
            errorText || error.message.response.data.errors,
            '',
            true
          )
          dispatch(action)
        })
    },
  })

  useEffect(() => {
    if (isTemplate) {
      return
    }

    const request = formik.values[REQUEST]

    if (request) {
      setLoading(true)
      getRequestPipeline(request.id).then(data => {
        if (data && data.length) {
          setPhaseList(data.map(getPhaseValue))
        } else {
          setPhaseList(null)
        }

        setLoading(false)

        if (!activity) {
          formik.setFieldValue(PHASE, '')
        }
      })
    }
  }, [formik.values[REQUEST]])

  const handleChangeRequest = request => {
    formik.setFieldValue(BUILDING, get(request, ['address_obj', 'value'], '-'))

    formik.setFieldValue(REQUEST, getRequestValue(request))
  }

  const handleChangeManager = manager =>
    formik.setFieldValue(MANAGER, getManagerValue(manager))

  const handleChangeCommitType = value =>
    formik.setFieldValue(COMMIT_TYPE, value)

  const handleChangePriority = value => formik.setFieldValue(PRIORITY, value)

  const handleChangeType = value => {
    formik.setFieldValue(TYPE, value)

    if (
      isTemplateText(
        formik.values[TITLE],
        formik.values[TYPE].icon.origin,
        activityTypes
      ) ||
      formik.values[TITLE] === ''
    ) {
      formik.setFieldValue(TITLE, value.name)
    }
  }

  const handleChangeDeadlineDate = value => {
    formik.setFieldValue(DEADLINE_DATE, value)
  }

  const handleChangeDeadlineTime = option => {
    formik.setFieldValue(DEADLINE_TIME, option.value)
  }

  const handleChangePhase = option => formik.setFieldValue(PHASE, option)

  const handleCancel = e => {
    if (formik.dirty) {
      formik.handleReset(e)
    } else {
      props.onClose()
    }
  }

  const handleEnableForm = () => setInstanceOpen(false)

  const layoutClass = classNames(styles.layout, {
    [styles['layout-enabled']]: isInstanceOpen,
  })

  if (!activityTypes || !activityPriorities) {
    return null
  }

  const options = activityPriorities.map(getPriorityValue)

  const handleClearPriority = () => formik.setFieldValue(PRIORITY, '')

  return (
    <Modal isOpen className={styles.modal}>
      <ModalHead title={modalTitle} className={styles['modal-head']} />
      {!isTemplate && activity && (
        <ModalRow className={styles['created-label']}>
          <ModalLabel>
            {t('CreatedTitle', {
              createdAt: formatDate(activity.created),
              createdBy: getUserName(activity.created_by),
            })}
          </ModalLabel>
        </ModalRow>
      )}
      {!isTemplate && activity && activity.closed_at && activity.closed_by && (
        <ModalRow className={styles['created-label']}>
          <ModalLabel>
            {t('FinishedTitle', {
              finishedAt: formatDate(activity.closed_at),
              finishedBy: getUserName(activity.closed_by),
            })}
          </ModalLabel>
        </ModalRow>
      )}
      <ModalCloseButton onClose={props.onClose} />
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <Scrollbar
          noScrollX
          noScrollY={isInstanceOpen}
          trackYProps={{
            renderer: ScrollbarTrackY,
          }}
          thumbYProps={{
            renderer: ThumbY,
          }}
          wrapperProps={{
            renderer: ScrollbarWrapper,
          }}
        >
          <ModalBody className={styles['modal-body']}>
            <div className={layoutClass} onClick={handleEnableForm} />
            {!isTemplate && (
              <>
                <ModalRow>
                  <ActivityModalLabel title={t('AttachedRequestLabel')} />
                </ModalRow>
                <ActivityModalRow>
                  <NewSelectAsync
                    buttonClass={styles['select-button']}
                    pageSize={10}
                    error={formik.errors[REQUEST]}
                    toggleMenuCb={setInstanceOpen}
                    className={styles.select}
                    api={getRequestOptions}
                    disabled={!!activity || !!request}
                    searchKey='search'
                    placeholder={t('AttachedRequestPLaceholder')}
                    getLabel={getRequestTitle}
                    getSelectedLabel={getRequestTitle}
                    view='dropdown'
                    selectedItems={
                      formik.values[REQUEST] ? [formik.values[REQUEST]] : []
                    }
                    permanentParams={{ archived: 0 }}
                    onClick={handleChangeRequest}
                  />
                </ActivityModalRow>
                {phaseList && (
                  <>
                    <ModalRow>
                      <ActivityModalLabel title={t('AttachedPhaseLabel')} />
                    </ModalRow>
                    <ModalRow>
                      <NewSelectSimple
                        toggleMenuCb={setInstanceOpen}
                        placeholder={t('PhasePlaceholder')}
                        className={styles.select}
                        options={phaseList}
                        value={formik.values[PHASE]}
                        error={formik.errors[PHASE]}
                        onChange={handleChangePhase}
                      />
                    </ModalRow>
                  </>
                )}
              </>
            )}
            <ModalRow>
              <ActivityModalLabel title={t('DescriptionLabel')} />
            </ModalRow>
            <ActivityModalRow>
              <InputField
                className={styles.input}
                error={formik.errors[TITLE]}
                name={TITLE}
                value={formik.values[TITLE]}
                placeholder={t('DescriptionPlaceholder')}
                onChange={formik.handleChange}
              />
            </ActivityModalRow>
            <ModalRow>
              <ActivityModalLabel title={t('ActivityTypeLabel')} />
            </ModalRow>
            <ActivityModalRow>
              <ActivityModalType
                activityTypes={activityTypes}
                value={formik.values[TYPE]}
                handleChangeType={handleChangeType}
              />
            </ActivityModalRow>
            {!isTemplate ? (
              <>
                <ModalRow>
                  <ActivityModalLabel title={t('DeadlineLabel')} />
                </ModalRow>
                <ActivityModalRow>
                  <ActivityModalDeadline
                    error={formik.errors[DEADLINE_TIME]}
                    toggleMenuCb={setInstanceOpen}
                    deadlineDate={formik.values[DEADLINE_DATE]}
                    deadlineTime={formik.values[DEADLINE_TIME]}
                    placeholder={t('DeadlinePlaceholder')}
                    onChangeDate={handleChangeDeadlineDate}
                    onChangeTime={handleChangeDeadlineTime}
                  />
                </ActivityModalRow>
              </>
            ) : (
              <>
                <ModalRow>
                  <ActivityModalLabel title={t('DeadlineDurationLabel')} />
                </ModalRow>
                <ActivityModalRow>
                  <InputField
                    className={styles.input}
                    error={formik.errors[DEADLINE_TEMPLATE]}
                    name={DEADLINE_TEMPLATE}
                    value={formik.values[DEADLINE_TEMPLATE]}
                    placeholder='4d 6h 45m'
                    onChange={formik.handleChange}
                  />
                </ActivityModalRow>
              </>
            )}
            <ModalRow>
              <ActivityModalLabel title={t('CommitTypeLabel')} />
            </ModalRow>
            <ActivityModalRow>
              <div style={{ display: 'flex' }}>
                <Button.Regular
                  className={styles['commit-button']}
                  view={
                    formik.values[COMMIT_TYPE] === DONE_COMMIT_TYPE
                      ? CONTAINED_VIEW
                      : OUTLINED_VIEW
                  }
                  onClick={() => handleChangeCommitType(DONE_COMMIT_TYPE)}
                >
                  {t('ChecklistDoneNotDone')}
                </Button.Regular>
                <Button.Regular
                  className={styles['commit-button']}
                  view={
                    formik.values[COMMIT_TYPE] === YESNO_COMMIT_TYPE
                      ? CONTAINED_VIEW
                      : OUTLINED_VIEW
                  }
                  onClick={() => handleChangeCommitType(YESNO_COMMIT_TYPE)}
                >
                  {t('QuestionYesNo')}
                </Button.Regular>
              </div>
            </ActivityModalRow>
            {!options ||
              (!!options.length && (
                <>
                  <ModalRow>
                    <ActivityModalLabel title={t('ActivityPriorityLabel')} />
                  </ModalRow>
                  <ActivityModalRow>
                    <ActivityModalPriority
                      placeholder={t('PriorityPlaceholder')}
                      className={styles.prioritySelect}
                      options={options}
                      value={formik.values[PRIORITY]}
                      disabled={!formik.values[PRIORITY]}
                      onToggle={setInstanceOpen}
                      onChange={handleChangePriority}
                      onClear={handleClearPriority}
                    />
                  </ActivityModalRow>
                </>
              ))}
            <ModalRow>
              <ActivityModalLabel title={t('NotesLabel')} />
            </ModalRow>
            <ActivityModalRow>
              <Textarea
                className={styles.textarea}
                value={formik.values[NOTES]}
                name={NOTES}
                onChange={formik.handleChange}
              />
            </ActivityModalRow>
            {!isTemplate && (
              <>
                <ModalRow>
                  <ActivityModalLabel title={t('ManagerLabel')} />
                </ModalRow>
                <ActivityModalRow>
                  <NewSelectAsync
                    pageSize={10}
                    error={formik.errors[MANAGER]}
                    toggleMenuCb={setInstanceOpen}
                    className={styles.select}
                    api={api.profile.getAdminAndManagerOptionList}
                    searchKey='fullname_or_email'
                    placeholder={t('ManagerPlaceholder')}
                    getLabel={getUserName}
                    getSelectedLabel={getManagerSelectedLabel}
                    view='dropdown'
                    selectedItems={
                      formik.values[MANAGER] ? [formik.values[MANAGER]] : []
                    }
                    permanentParams={{
                      view_request: request?.id || undefined,
                      status: ACTIVE_STATUS,
                    }}
                    onClick={handleChangeManager}
                  />
                </ActivityModalRow>
                <ModalRow>
                  <ActivityModalLabel title={t('BuildingLabel')} />
                </ModalRow>
                <ActivityModalRow>
                  <InputField
                    disabled
                    className={styles.input}
                    value={formik.values[BUILDING]}
                    name={BUILDING}
                  />
                </ActivityModalRow>
              </>
            )}
          </ModalBody>
        </Scrollbar>
        <ModalButtons className={styles.buttons}>
          <Button.Save
            type='submit'
            disabled={
              !formik.dirty || formik.isSubmitting || !formik.isValid || loading
            }
          >
            {modalSaveButton}
          </Button.Save>
          <Button.Cancel disabled={formik.isSubmitting} onClick={handleCancel}>
            {t('Common:Cancel')}
          </Button.Cancel>
        </ModalButtons>
      </form>
    </Modal>
  )
}

export default ActivityModal
