// @flow

import React, { useState } from 'react'
import type { Node } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { useTranslation } from 'react-i18next'

import { createFile, getFile } from '../../core/api/api.file'

import { getLanguageCode } from '../../utils/commonSelectors'
import { isDeLocale } from '../../utils/utils'

import ManagersBar from '../Building/ManagersBar'

import GroupNameModal from './GroupNameModal'
import ExternalIdModal from '../Building/ExternalIdModal'
import ConfirmUpdateManagersModal from './ConfirmUpdateManagersModal'

import { SimpleBlock, Header, CellsBar, Cell } from '../Block'
import Button from '../Button'
import Avatar from '../AvatarEditable'
import Date from '../Date'

import styles from '../Building/BuildingInfo.module.scss'
import { MANAGERS_LIMIT } from '../../constants'

type Props = {
  data: Object,
  onSave: Object => void,
  onSaveManagers: (Array<Object>) => void,
}

const BuildingGroupInfo = (props: Props): Node => {
  const {
    data: {
      name,
      avatar_obj,
      created,
      inhabitant_count,
      flat_count,
      external_id,
      permissions,
      responsible_user_objs,
    },
    onSave,
    onSaveManagers,
  } = props
  const languageCode = useSelector(getLanguageCode)

  const { t } = useTranslation('Building')
  const dispatch = useDispatch()

  const [working, setWorking] = useState(false)
  const [editName, setEditName] = useState(false)
  const [editExtId, setEditExtId] = useState(false)
  const [editingManagers, setEditingManagers] = useState(false)
  const [managersModal, setManagersModal] = useState(false)
  const [managers, setManagers] = useState(responsible_user_objs || [])

  const handleEditAvatar = avatar => {
    if (avatar) {
      const { dataUrl, filename: name } = avatar
      createFile({ dataUrl, name }).then(file => checkFile(file.id))
    }
  }

  const handleUpdateName = name => {
    setEditName(false)
    setWorking(true)
    onSave({ name })
  }

  const handleUpdateExternalId = external_id => {
    setEditExtId(false)
    setWorking(true)
    onSave({ external_id })
  }

  const checkFile = id => {
    getFile(id).then(data => {
      if (data.checking) {
        setTimeout(() => checkFile(data.id), 500)
      } else {
        setWorking(false)
        onSave({ avatar: data.id })
      }
    })
  }

  const handleSaveManagers = () => setManagersModal(true)

  const confirmSaveManagers = perform_for_buildings => {
    setWorking(true)
    onSaveManagers({
      responsible_users: managers.map(m => m.id),
      perform_for_buildings,
    })
    setManagersModal(false)
  }

  const getAvatarBgImage = () => {
    const url = avatar_obj.preview || avatar_obj.origin

    return url ? `url(${url})` : ''
  }

  return (
    <SimpleBlock working={working}>
      <Header
        header
        goBack={() => dispatch(push(`/buildings/groups`))}
        textTitle={name}
        className={styles.groupHeader}
      >
        {permissions?.can_edit ? (
          <div icon='true' className='roundbutton--subtitle'>
            <Avatar
              image={avatar_obj}
              title={t('AvatarLoad')}
              style={{ border: '1px solid rgb(224, 224, 224)' }}
              onStartEdit={() => setWorking(true)}
              onFinishEdit={handleEditAvatar}
            />{' '}
            :
          </div>
        ) : (
          <div
            icon='true'
            className='mprofile-view-user__userpic mprofile-view-user__userpic-building'
            style={{ backgroundImage: getAvatarBgImage() }}
          />
        )}
        <span className={styles.groupName}>{name}</span>
        {permissions?.can_edit && (
          <Button.Edit onClick={() => setEditName(true)} />
        )}
      </Header>
      <CellsBar>
        <Cell title={t('Created')}>
          <Date
            date={created}
            dateFormat={`D${isDeLocale(languageCode) ? '.' : ''} MMM YYYY`}
          />
        </Cell>
        <Cell title={t('InfoDwellers')}>{inhabitant_count || 0}</Cell>
        <Cell title={t('InfoFlats')}>{flat_count || 0}</Cell>
        <Cell title={t('GroupExternalId')} className={styles.externalId}>
          <div className={styles.externalIdText}>
            <span className={styles.externalIdEllipse} title={external_id}>
              {external_id || t('ExternalIdNotDefined')}
            </span>
            {permissions?.can_edit && (
              <Button.Edit onClick={() => setEditExtId(true)} />
            )}
          </div>
        </Cell>
      </CellsBar>
      <ManagersBar
        data={{ owner_objs: managers, permissions }}
        title={t('GroupResponsibleManagers')}
        working={working}
        editingManagers={editingManagers}
        saveManagers={handleSaveManagers}
        editManagers={() => setEditingManagers(true)}
        cancelManagers={() => {
          setManagers(responsible_user_objs)
          setEditingManagers(false)
        }}
        updateManagers={setManagers}
        limitError={{
          text: t('GroupManagersLimitText', { limit: MANAGERS_LIMIT }),
          title: t('GroupManagersLimitTitle'),
        }}
        max={MANAGERS_LIMIT}
      />
      {editName && (
        <GroupNameModal
          name={name}
          updateName={handleUpdateName}
          onClose={() => setEditName(false)}
        />
      )}
      {editExtId && (
        <ExternalIdModal
          externalId={external_id}
          updateExternalId={handleUpdateExternalId}
          title={t('GroupExternalId')}
          onClose={() => setEditExtId(false)}
        />
      )}
      {managersModal && (
        <ConfirmUpdateManagersModal
          isOpen={managersModal}
          title={t('GroupManagersEditing')}
          text={t('GroupManagersEditingText')}
          yesText={t('GroupManagersPerformForBuildings')}
          noText={t('GroupManagersNotPerformForBuildings')}
          onClose={() => setManagersModal(false)}
          onConfirm={confirmSaveManagers}
        />
      )}
    </SimpleBlock>
  )
}

export default BuildingGroupInfo
