// @flow

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch, useLocation } from 'react-router-dom'

import ContractorInfo from './ContractorInfo'

import Contacts from './Contacts'
import BuildingGallery from '../../BuildingGallery'
import Categories from './Categories'
import Files from '../../Files'

import RouterTabs from '../../Tabs/RouterTabs'
import Pan from '../../Tabs/Pan'

import Loader from '../../Loader'
import { redirectTo404 } from '../../../utils/routing'

import { getContractor } from '../../../core/api/api.contractor'

const ContractorView = () => {
  const { t } = useTranslation('Contractors')
  const match = useRouteMatch()
  const location = useLocation()
  const id = match.params.contractorId

  const [loading, setLoading] = useState(true)
  const [contractor, setContractor] = useState({})

  useEffect(() => {
    if (loading && id) {
      getContractor(id)
        .then(data => {
          setContractor(data)
        })
        .catch(error => redirectTo404(error))
        .finally(() => setLoading(false))
    }
  }, [loading])

  if (loading) {
    return <Loader text={false} type='big' />
  }

  return (
    <div className='content__col'>
      <ContractorInfo contractor={contractor} setLoading={setLoading} />
      <RouterTabs className='tabs tabs-style-transparent' openFirst='info'>
        <Pan label={t('ContactDetails')} linkHash='#info'>
          <Contacts contractor={contractor} onUpdate={() => setLoading(true)} />
        </Pan>
        <Pan label={t('Buildings')} linkHash='#buildings'>
          <BuildingGallery
            noMargin
            contractorId={id}
            canChange={!contractor.archived}
          />
        </Pan>
        <Pan label={t('Categories')} linkHash='#categories'>
          <Categories contractorId={id} canChange={!contractor.archived} />
        </Pan>
        <Pan label={t('Documents')} linkHash='#documents'>
          <Files
            contractorId={id}
            directoryId={contractor.directory_id}
            match={match}
            location={location}
          />
        </Pan>
      </RouterTabs>
    </div>
  )
}

export default ContractorView
