// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { inRange, isUndefined, take } from 'lodash-es'
import { compose } from 'redux'

import ShowAllButton from '../../ShowAllButton'
import GreatPreview from './DocumentGreatPreview'
import Lightbox from '../../LightboxGallery'
import Preview from '../DocumentPreview'
import TileRow from '../../TileList/TileRow'
import MassDownload from '../../MassDownload'
import { GALLERY_ITEM_INDENT } from '../../../constants'
import { getThemeColor } from '../../../utils/commonSelectors'
import { isMediaFile } from '../../../utils/file'
import { getPreviewIndex, getRows } from './DocumentsView.utils'
import {
  GREAT_PREVIEW_SIZE,
  SHORT_VIEW_ROWS_COUNT,
  MIN_ROW_LENGTH,
  MAX_ROW_LENGTH,
} from './DocumentsView.constants'

import styles from './DocumentsView.module.scss'

const SIGNATURE_TIMEOUT_SECS = 120

type Props = {
  files: Array<Object>,
  itemIndent: number,
  maxRowLength: number,
  minRowLength: number,
  needUpdateSnap?: boolean,
  showGreatPreview?: boolean,
  t: string => string,
  themeColor: string,
  withoutBlanks?: boolean,
  withoutDownload?: boolean,
}
type State = {
  activeIndex: ?number,
  greatPreviewIndex: number,
  rows: Array<Object>,
  shortView: boolean,
  shouldReloadFiles: boolean,
  signatureTimeout: boolean,
}

class DocumentsView extends Component<Props, State> {
  static defaultProps = {
    itemIndent: GALLERY_ITEM_INDENT,
    maxRowLength: MAX_ROW_LENGTH,
    minRowLength: MIN_ROW_LENGTH,
  }

  state = this.getPreparedState()

  componentDidMount() {
    setTimeout(
      () => this.setState({ signatureTimeout: true }),
      SIGNATURE_TIMEOUT_SECS * 1000
    )
  }

  componentDidUpdate(prev: Props) {
    const { needUpdateSnap } = this.props

    if (needUpdateSnap !== prev.needUpdateSnap) {
      const { shortView } = this.state
      this.setState(this.getPreparedState(shortView))
    }
  }

  getPreparedState(shortened?: boolean) {
    const previewIndex = this.getGreatPreviewIndex()
    const rows = this.getRows(previewIndex)

    const shortView = isUndefined(shortened)
      ? rows.length > SHORT_VIEW_ROWS_COUNT
      : shortened

    return {
      activeIndex: null,
      greatPreviewIndex: previewIndex,
      rows: shortView ? take(rows, SHORT_VIEW_ROWS_COUNT) : rows,
      shortView,
      shouldReloadFiles: false,
      signatureTimeout: false,
    }
  }

  getGreatPreviewIndex() {
    const { showGreatPreview, files = [], minRowLength } = this.props
    const { length } = files

    if (!showGreatPreview || inRange(length, 2, minRowLength + 1)) {
      return -1
    }

    return getPreviewIndex(files)
  }

  getRows(previewIndex: number) {
    const { files, minRowLength, maxRowLength } = this.props

    return getRows(files, previewIndex, minRowLength, maxRowLength)
  }

  getRowLength = ({ items }: Object) => {
    const { minRowLength, withoutBlanks } = this.props

    if (items.length < minRowLength) {
      const media = items.find(isMediaFile)

      if (!media || !withoutBlanks) {
        return minRowLength
      }
    }

    return items.length
  }

  showAll = () => {
    const { greatPreviewIndex, signatureTimeout } = this.state

    this.setState({
      rows: this.getRows(greatPreviewIndex),
      shortView: false,
      shouldReloadFiles: signatureTimeout,
    })
  }

  showLightbox =
    ({ id }: Object) =>
    () => {
      const { files } = this.props
      const activeIndex = files.findIndex(f => f.id === id)
      this.setState({ activeIndex })
    }

  closeLightbox = () => {
    this.setState({ activeIndex: null })
  }

  renderFile = (file: Object) => (
    <Preview
      canHover
      reload={this.state.shouldReloadFiles}
      file={file}
      key={`file-${file.id}`}
      scope='post'
      postUuid={this.props.postUuid}
      onClick={this.showLightbox(file)}
    />
  )

  renderShowAll = () => {
    const { files } = this.props
    const { shortView } = this.state

    return (
      shortView && (
        <ShowAllButton
          text={this.props.t('ShowAll')}
          count={files.length}
          onClick={this.showAll}
        />
      )
    )
  }

  renderGreatPreview = () => {
    const { greatPreviewIndex: index } = this.state

    if (index < 0) {
      return null
    }

    const { files } = this.props
    const file = files[index]

    return (
      <GreatPreview
        data={file}
        thumbnailSize={GREAT_PREVIEW_SIZE}
        onClick={this.showLightbox(file)}
      />
    )
  }

  render() {
    const {
      files,
      themeColor,
      itemIndent,
      withoutDownload,
      showGreatPreview,
      scope,
    } = this.props
    const { activeIndex, rows } = this.state

    if (!files.length) {
      return null
    }

    return (
      <div className={styles.gallery} style={{ '--themeColor': themeColor }}>
        {files.length > 1 && (
          <div
            className={
              showGreatPreview
                ? styles.attachmentsHeader
                : styles.postAttachmentsHeader
            }
          >
            <div className={styles.count}>{`${this.props.t(
              'Mail:Attachments'
            )}: ${files.length}`}</div>
            <MassDownload fileIds={files.map(f => f.id)} />
          </div>
        )}
        {this.renderGreatPreview()}
        <div
          className={styles.rows}
          style={{ '--itemIndent': `${itemIndent}px` }}
        >
          {rows.map(row => (
            <TileRow
              itemIndent={itemIndent}
              key={row.key}
              length={this.getRowLength(row)}
            >
              {row.items.map(this.renderFile)}
            </TileRow>
          ))}
        </div>
        {this.renderShowAll()}
        <Lightbox
          newGallery
          takeOrigin={this.props.takeOrigin}
          activeIndex={activeIndex}
          images={files}
          withoutDownload={withoutDownload}
          scope={scope}
          onClose={this.closeLightbox}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  themeColor: getThemeColor(state),
})

export default compose(
  withTranslation('File'),
  connect(mapStateToProps)
)(DocumentsView)
