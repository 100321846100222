// @flow

import Add from './Add'
import Arrow from './Arrow'
import Back from './Back'
import Cancel from './Cancel'
import Close from './Close'
import CreateEmail from './CreateEmail'
import Copy from './Copy'
import Cross from './Cross'
import Edit from './Edit'
import Minimize from './MinimizeButton'
import Regular from './Regular'
import Remove from './Remove'
import Save from './Save'
import SaveList from './SaveList'
import Search from './Search'
import Select from './Select'
import SelectInput from './SelectInput'
import Sort from './Sort'
import Text from './Text'
import Toggle from './Toggle'
import Delete from './Delete'
import Restore from './Restore'
import Pin from './Pin'
import Upload from './Upload'
import NewSelect from './NewSelect'
import Square from './Square'
import SendFile from './SendFile'
import Filter from './Filter'

export default {
  Add,
  Arrow,
  Back,
  Cancel,
  Close,
  CreateEmail,
  Copy,
  Cross,
  Edit,
  Pin,
  Minimize,
  Regular,
  Remove,
  Restore,
  Save,
  SaveList,
  Search,
  Select,
  SelectInput,
  SendFile,
  Sort,
  Text,
  Toggle,
  Delete,
  Upload,
  NewSelect,
  Square,
  Filter,
}
