//@flow

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import {
  getAutomations,
  addAutomation,
  deleteAutomation,
} from '../../core/api/api.automate'
import { useLoad, usePathname, useSelected } from '../../hooks'

import Content from '../Content'
import Loader from '../Loader'
import AutomateToolbox from './AutomateTable/AutomateToolbox'
import AutomateTable from './AutomateTable/AutomateTable'
import EmptyList from '../EmptyList'
import BottomPagination from '../Pagination/BottomPagination'

import AutomateModal from './AutomateModal'
import Modal from '../Modal'
import ConfirmationPopup from '../modals/ConfirmationPopup'

import addRedirectToFirstPage from '../../hocs/addRedirectToFirstPage'

type Props = {
  setCount: number => void,
}

const AutomateMain = (props: Props): Node => {
  const { setCount } = props
  const { t } = useTranslation('Automate')
  const isLocked = usePathname()
  const [data, isLoading, isError, load] = useLoad(
    getAutomations,
    null,
    isLocked
  )
  const [selectedItems, changeSelected, setSelected, isAllSelected] =
    useSelected()
  const [modal, setModal] = useState(false)
  const [removeModal, setRemoveModal] = useState(false)
  const [removingItems, setRemovingItems] = useState(false)

  useEffect(() => {
    if (isLoading) {
      setSelected([])
    }
  }, [isLoading])

  useEffect(() => {
    setCount(data?.meta?.count)
  }, [data])

  const handleAdd = () => setModal(true)

  const addAutomate = (name, trigger_event) => {
    hideModal()
    addAutomation({ name, trigger_event }).then(() => {
      load()
    })
  }

  const handleRemove = () => {
    let removed = 0
    setRemovingItems(true)
    selectedItems.forEach(item => {
      deleteAutomation(item).then(() => {
        removed++

        if (removed === selectedItems.length) {
          setRemovingItems(false)
          setRemoveModal(false)
          load()
        }
      })
    })
  }

  const hideModal = () => setModal(false)
  const hideRemoveModal = () => setRemoveModal(false)

  if (isLoading || removingItems) {
    return <Loader type='big' text={false} />
  }

  if (isError) {
    return null
  }

  const permissions = {
    can_create: data?.permissions.can_create,
    can_delete:
      Array.isArray(data?.results?.objects) &&
      data.results.objects.every(a => a.permissions.can_delete),
  }

  return (
    <Content>
      <AutomateToolbox
        selectedItems={selectedItems}
        setSelected={setSelected}
        checked={isAllSelected(data?.results?.objects || [])}
        items={data?.results?.objects}
        meta={data?.meta}
        permissions={permissions}
        isLoading={isLoading}
        selected={
          !!selectedItems.length && !isAllSelected(data?.results?.objects || [])
        }
        onRemove={() => setRemoveModal(true)}
        onAdd={handleAdd}
      />
      {data?.results?.objects?.length > 0 && (
        <AutomateTable
          isLoading={isLoading}
          list={data.results?.objects}
          selectedItems={selectedItems}
          onSelect={changeSelected}
          onRemove={id => {
            changeSelected(id)
            setRemoveModal(true)
          }}
        />
      )}
      {!data?.results?.objects?.length && (
        <EmptyList
          embedded
          canAdd={permissions.can_create}
          btnText={t('Add')}
          icon='automate'
          title={t('EmptyList')}
          onClick={handleAdd}
        />
      )}
      {data?.results?.objects?.length > 0 && (
        <BottomPagination meta={data.meta} classes='paginator-2' />
      )}
      {modal && <AutomateModal addAutomate={addAutomate} onClose={hideModal} />}
      {removeModal && (
        <Modal isOpen onRequestClose={hideRemoveModal}>
          <ConfirmationPopup
            isAsync
            confirm={t('Delete')}
            title={t('DeleteTitle')}
            text={t(
              selectedItems.length > 1 ? 'ConfirmDelete' : 'ConfirmDeleteOne'
            )}
            onClose={hideRemoveModal}
            onOk={handleRemove}
          />
        </Modal>
      )}
    </Content>
  )
}

export default addRedirectToFirstPage(AutomateMain)
