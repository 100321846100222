// @flow

import React, { Component } from 'react'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { isEmpty } from 'lodash-es'
import { compose } from 'redux'
import type { Dispatch } from 'redux'

import Notifications from '../Notification/NotificationList'
import Loader from '../Loader'
import BuildingInfo from '../Building/BuildingInfo'
import AddressSection from '../Address/AddressSection/'
import * as actions from './BuildingCreate.actionTypes'
import Modal from '../Modal'
import ConfirmationPopup from '../modals/ConfirmationPopup'
import { getUk } from '../../utils/commonSelectors'
import ConfirmUpdateManagersModal from '../BuildingGroupView/ConfirmUpdateManagersModal'

import { MANAGERS_LIMIT } from '../../constants'

type Props = {
  dispatch: Dispatch,
  error: any,
  initiated: boolean,
  notifications: Array<Object>,
  params: Object,
  permissions: Object,
  t: string => string,
  working: boolean,
}

class BuildingCreate extends Component<Props> {
  constructor(props) {
    super(props)

    this.state = {
      data: {},
      building_groups: [],
      isPromo: false,
    }
  }

  componentDidMount() {
    this.props.dispatch({ type: actions.BUILDING_CREATE_INITIATNG })
  }

  componentDidUpdate() {
    const { permissions } = this.props

    if (!isEmpty(permissions) && !permissions.can_create) {
      this.props.dispatch(push('/'))
    }
  }

  save = (data, building_groups, isPromo) => {
    if (building_groups.length > 0) {
      this.setState({ data, building_groups, isPromo })
      this.props.dispatch({ type: actions.BUILDING_SHOW_CONFIRM })
    } else {
      this.confirmSave(data, building_groups, false, isPromo)
    }
  }

  confirmSaveManagers = update_responsible_users =>
    this.confirmSave(
      this.state.data,
      this.state.building_groups,
      update_responsible_users,
      this.state.isPromo
    )

  confirmSave = (data, building_groups, update_responsible_users, isPromo) => {
    let address = {
      ...data,
    }

    if (data.lattitude && data.longitude) {
      address = {
        ...address,
        widget_lattitude: +data.lattitude,
        widget_lattitude_str: data.lattitude,
        widget_longitude: +data.longitude,
        widget_longitude_str: data.longitude,
      }
    }

    this.closeManagersModal()

    this.props.dispatch({
      type: actions.BUILDING_CREATE_SAVE,
      address,
      building_groups,
      update_responsible_users,
      isPromo,
    })
  }

  closePopUp = () => {
    this.props.dispatch({ type: actions.BUILDING_HIDE_EROR })
  }

  closeManagersModal = () => {
    this.props.dispatch({ type: actions.BUILDING_HIDE_CONFIRM })
  }

  closeLimitPopUp = () => {
    const { createdId } = this.props
    this.props.dispatch({ type: actions.BUILDING_HIDE_MANAGERS_LIMIT })
    this.props.dispatch(push(`/building/${createdId}`))
  }

  render() {
    const {
      initiated,
      working,
      notifications,
      error,
      t,
      location,
      history,
      managersModal,
      managersLimit,
    } = this.props

    if (!initiated) {
      return <Loader text={false} type='big' />
    }

    const errorText = error
      ? Object.keys(error.errors).reduce(
          (prev, next) => `${prev}${t(error.errors[next])} <br />`,
          ''
        )
      : ''

    return (
      <div className='content__col'>
        <Notifications
          style={{ marginTop: '1.5rem' }}
          notifications={notifications}
        />
        <BuildingInfo
          working={working}
          noFlats={false}
          location={location}
          history={history}
        />
        <AddressSection
          editable
          title={t('MainInfo')}
          working={working}
          initiated={initiated}
          noFlats={false}
          save={this.save}
        />
        <Modal
          className='Modal__Bootstrap modal-dialog'
          isOpen={!!errorText}
          contentLabel=''
          onRequestClose={this.closePopUp}
        >
          {error && (
            <ConfirmationPopup
              title={t('Common:Error')}
              text={errorText}
              showCancel={false}
              confirm={t('Common:Close')}
              onOk={this.closePopUp}
              onClose={this.closePopUp}
            />
          )}
        </Modal>
        {managersModal && !working && (
          <ConfirmUpdateManagersModal
            isOpen={managersModal}
            title={t('GroupManagersEditing')}
            text={t('GroupManagersAddBuldingText')}
            yesText={t('GroupManagersPerfomrForBuilding')}
            noText={t('GroupManagersNotPerfomrForBuilding')}
            onClose={this.closeManagersModal}
            onConfirm={this.confirmSaveManagers}
          />
        )}
        {managersLimit && !working && (
          <Modal
            className='Modal__Bootstrap modal-dialog'
            isOpen={!!managersLimit}
            contentLabel=''
            onRequestClose={this.closeLimitPopUp}
          >
            <ConfirmationPopup
              title={t('BuildingManagersLimitTitle')}
              text={t('BuildingUpdateManagersLimitText', {
                limit: MANAGERS_LIMIT,
              })}
              showCancel={false}
              confirm={t('Common:Ok')}
              onOk={this.closeLimitPopUp}
              onClose={this.closeLimitPopUp}
            />
          </Modal>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  uk: getUk(state),
  ...state.buildingCreate,
})

export default compose(
  withTranslation('Building'),
  connect(mapStateToProps)
)(BuildingCreate)
