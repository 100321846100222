// @flow

import React from 'react'
import { useDispatch } from 'react-redux'
import type { Element, Node } from 'react'

import SelectCustom from '../Select/SelectCustom'
import ConfirmationPopup from '../modals/ConfirmationPopup'
import { useTranslation } from 'react-i18next'
import {
  getRequest,
  readThread,
  unreadThread,
} from '../../core/api/api.request'
import { REQUEST_VIEW_UPDATE_THREAD_COUNTERS } from '../../containers/RequestView/RequestView.constants'

type User = {
  name: string,
  owner: number,
  status: number,
}

type Props = {
  canDelete: boolean,
  canUpdateRole: boolean,
  getParams: () => {
    counters: number,
    group: string,
    page: number,
  },
  setModal: (?Element<'div'>) => void,
  t: (string, ?Object) => string,
  user: User,
}

const ExtraActions = (props: Props): Node => {
  const { uuid, requestId, audience, unviewedMsgCount, notes, activities } =
    props

  const { t } = useTranslation('Request')

  const dispatch = useDispatch()

  const hideModal = () => {
    props.setActionModal(null)
  }

  const handleOpenThreadModal = () => {
    window.open(`/request/${requestId}/${uuid}/emails/`)
  }

  const handleChangeAudience = () => {
    props.openAudienceModal({ threadUuid: uuid, audience })
  }

  const handleChangeActivities = () => {
    props.openActivitiesModal({ threadUuid: uuid, activities })
  }

  const handleChangeNotes = () => {
    props.openNotesModal({ threadUuid: uuid, notes })
  }

  const setUnread = () => {
    unreadThread(requestId, uuid).finally(() => {
      hideModal()

      props.setLoading(true)

      getRequest(requestId).then(data => {
        const {
          manager_thread_read,
          provider_thread_read,
          dweller_thread_read,
        } = data

        dispatch({
          type: REQUEST_VIEW_UPDATE_THREAD_COUNTERS,
          counters: {
            manager_thread_read,
            provider_thread_read,
            dweller_thread_read,
          },
        })
      })
    })
  }

  const setRead = () => {
    readThread(requestId, uuid).finally(() => {
      hideModal()

      props.setLoading(true)

      getRequest(requestId).then(data => {
        const {
          manager_thread_read,
          provider_thread_read,
          dweller_thread_read,
        } = data

        dispatch({
          type: REQUEST_VIEW_UPDATE_THREAD_COUNTERS,
          counters: {
            manager_thread_read,
            provider_thread_read,
            dweller_thread_read,
          },
        })
      })
    })
  }

  const handleSetRead = () => {
    props.setActionModal(
      <ConfirmationPopup
        title={t('SetReadTitle')}
        text={t('SetReadText')}
        confirm={t('SetReadButtonTitle')}
        cancel={t('Common:Cancel')}
        onClose={hideModal}
        onOk={setRead}
      />
    )
  }

  const handleSetUnread = () => {
    props.setActionModal(
      <ConfirmationPopup
        title={t('SetUnreadTitle')}
        text={t('SetUnreadText')}
        confirm={t('SetUnreadButtonTitle')}
        cancel={t('Common:Cancel')}
        onClose={hideModal}
        onOk={setUnread}
      />
    )
  }

  const getOptions = () => {
    let options = [
      {
        value: 'openInTab',
        label: t('OpenInTab'),
        icon: 'open',
        handler: handleOpenThreadModal,
      },
      {
        value: 'changeAudience',
        label: t('ChangeAudience'),
        icon: 'move',
        handler: handleChangeAudience,
      },
      {
        value: 'changeActivities',
        label: t('EditAttachedActivities'),
        icon: 'threadActivity',
        handler: handleChangeActivities,
      },
      {
        value: 'changeNotes',
        label: t('ChangeNotes'),
        icon: 'pencil',
        handler: handleChangeNotes,
      },
    ]

    if (unviewedMsgCount === 0) {
      options.push({
        value: 'setThreadUnRead',
        label: t('SetThreadUnRead'),
        icon: 'pencil',
        handler: handleSetUnread,
      })
    } else {
      options.push({
        value: 'setThreadRead',
        label: t('SetThreadRead'),
        icon: 'pencil',
        handler: handleSetRead,
      })
    }

    if (props.openMoveModal) {
      options.push({
        value: 'moveThread',
        label: t('MoveThread'),
        icon: 'request',
        handler: () => props.openMoveModal(uuid),
      })
    }

    options.push(
      options.push({
        value: 'removeThread',
        label: t('RemoveThread'),
        icon: 'remove',
        handler: props.openRemoveModal(uuid),
      })
    )

    return options
  }

  const preventOpen = e => {
    e.stopPropagation()
  }

  return (
    <div className='table-extra table-extra--settings'>
      <SelectCustom
        options={getOptions()}
        onClick={preventOpen}
        onChange={opt => opt.handler()}
      />
    </div>
  )
}

export default ExtraActions
