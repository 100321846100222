//@flow

import { FORMIK_EMPTY_VALUE } from '../../../constants'

export const TYPE_OF_ROOM = 'room_type'
export const FLAT_CATEGORY = 'category'
export const FLAT_EQUIPMENT = 'equipment'
export const FLAT_TYPE_RENT_PAYMENT = 'rent_payment_type'
export const ENTRANCE = 'entrance'
export const FLAT_CONDITION = 'condition'
export const PROPERTY_SHARE = 'property_share'
export const UNIT_NUMBER = 'unit_number'
export const CONTRACT_TYPE = 'contract_type'
export const FLAT_SPECIFICS = 'flat_specifics'
export const HEAT_TYPE = 'heat_type'

export const CATEGORY_MAX_LENGTH = 256
export const EQUIPMENT_MAX_LENGTH = 1024
export const TYPE_RENT_PAYMENT_MAX_LENGTH = 256
export const CONDITION_MAX_LENGTH = 1024
export const UNIT_NUMBER_MAX_LENGTH = 999999999
export const SHARE_MAX = 99999
export const SHARE_MIN = 0
export const TOTAL_MAX = 99999
export const TOTAL_MIN = 1

export const KEYS = [
  TYPE_OF_ROOM,
  FLAT_CATEGORY,
  FLAT_EQUIPMENT,
  FLAT_TYPE_RENT_PAYMENT,
  FLAT_CONDITION,
  PROPERTY_SHARE,
  ENTRANCE,
  UNIT_NUMBER,
  CONTRACT_TYPE,
  FLAT_SPECIFICS,
  HEAT_TYPE,
]

export const DEFAULT_VALUES: Object = {
  [TYPE_OF_ROOM]: FORMIK_EMPTY_VALUE,
  [FLAT_CATEGORY]: FORMIK_EMPTY_VALUE,
  [FLAT_EQUIPMENT]: FORMIK_EMPTY_VALUE,
  [FLAT_TYPE_RENT_PAYMENT]: FORMIK_EMPTY_VALUE,
  [FLAT_CONDITION]: FORMIK_EMPTY_VALUE,
  [HEAT_TYPE]: FORMIK_EMPTY_VALUE,
  [PROPERTY_SHARE]: {
    total: FORMIK_EMPTY_VALUE,
    share: FORMIK_EMPTY_VALUE,
  },
}
