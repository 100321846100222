// @flow

import { getApi, postApi } from './api.core'

export const authorize = (params: { password: string, username: string }) =>
  postApi('rest-auth/login/', params)
export const logout = () => postApi('rest-auth/logout/')
export const recover = (params: { email: string }) =>
  postApi('api/system/reset-password/', params)
export const ssoLogin = () => getApi('api/sso/login/')

export const getJWTtoken = () => postApi('api/jwt/token/for-authenticated/')
export const refreshJWTtoken = params =>
  postApi('api/jwt/token/refresh/', params)
