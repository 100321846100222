// @flow

import React, { useRef, useEffect } from 'react'
import { withTranslation } from 'react-i18next'

import Icon from './CardIcon'

type Props = {
  action_id?: number,
  automate?: boolean,
  cardType: string,
  t: string => string,
}

const CardHeader = (props: Props) => {
  const { cardType, automate, action_id } = props
  const headerRef = useRef(null)

  useEffect(() => {
    if (action_id < 0) {
      headerRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])

  return (
    <div className='request-constructor__card--title' ref={headerRef}>
      <Icon automate={automate} type={cardType} />
      <div>{props.t(cardType)}</div>
    </div>
  )
}

export default withTranslation('RequestConstructor')(CardHeader)
