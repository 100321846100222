// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import { STATUSES_COLORS } from '../../../../ui/MainColors.constants'

import { getRequestPipeline } from '../../../../core/api/api.pipeline'
import { getNews } from '../../../../core/api/api.newsband'
import BS from '../../../../utils/browserStorage'
import NewSelectSimple from '../../../NewSelectSimple'

import ConfirmationPopup from '../../../modals/ConfirmationPopup'
import Modal from '../../../Modal'
import { getUser } from '../../../../utils/commonSelectors'
import { isAdminUser, isManagerUser } from '../../../../utils/utils'
import { useSelector } from 'react-redux'

import { isAppropriateInterval } from '../../../Post/PostInfo/PostInfo.utils'

import { COMPLETED_REQUEST_STATUS } from '../../../../constants'

type Props = {
  onChange: Object => void,
  onEdit: Object => void,
  requestId: number,
  setPostsToOffline: (Array<string>) => void,
  statuses: any,
  statusId: number,
}

const StatusSelect = (props: Props): Node => {
  const { setPostsToOffline, statusId, statuses, requestId } = props
  const NOTICE_TITLE = 'notice_warn_closing'
  const { t } = useTranslation('Request')

  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isOpenPostsModal, setIsOpenPostsModal] = useState(false)
  const [onlinePostsArr, setOnlinePostsArr] = useState([])
  const user = useSelector(getUser)

  const isPermitted = isAdminUser(user) || isManagerUser(user)
  const isShowed = BS.get(NOTICE_TITLE)

  const status = statuses.find(s => s.id === statusId)

  const getOptions = () => {
    return statuses.map(status => ({
      value: status.id,
      label: t(`${status.title}`),
      style: { color: STATUSES_COLORS[status.css] },
    }))
  }

  const onToggleCheck = isCheck =>
    isCheck ? BS.set(NOTICE_TITLE, true) : BS.remove(NOTICE_TITLE)

  const onAccept = () => {
    setIsOpenModal(false)

    if (!isOpenPostsModal) {
      props.onChange(COMPLETED_REQUEST_STATUS)
    }
  }

  const onClose = () => {
    setIsOpenModal(false)
    setIsOpenPostsModal(false)
  }

  const onAcceptPosts = setOffline => {
    props.onChange(COMPLETED_REQUEST_STATUS)

    if (setOffline) {
      setPostsToOffline(onlinePostsArr)
    }

    setIsOpenPostsModal(false)
  }

  const onClosePosts = () => setIsOpenPostsModal(false)

  const onOpen = isOpen => isOpen && props.onEdit()

  const onChange = async status => {
    if (isPermitted && status.value === COMPLETED_REQUEST_STATUS) {
      const phases = !isShowed ? await getRequestPipeline(requestId) : null
      const news = await getNews({ request: requestId })
      const posts = news?.results?.objects || []
      const onlinePosts = posts
        .filter(
          p =>
            !p.spam &&
            p.permissions?.can_edit_date_period &&
            isAppropriateInterval(p.date_from, p.date_to)
        )
        .map(p => p.uuid)
      let warning = false

      if (onlinePosts.length > 0) {
        warning = true
        setIsOpenPostsModal(true)
        setOnlinePostsArr(onlinePosts)
      }

      if (Array.isArray(phases) && phases.some(p => p.has_undone_activities)) {
        warning = true
        setIsOpenModal(true)
      }

      if (!warning) {
        props.onChange(status.value)
      }
    } else {
      props.onChange(status.value)
    }
  }

  return (
    <>
      <NewSelectSimple
        options={getOptions()}
        selectedItems={status.id ? [{ ...status, value: status.id }] : []}
        buttonType='edit'
        onOpen={onOpen}
        onChange={onChange}
      />
      {isOpenModal && (
        <Modal isOpen>
          <ConfirmationPopup
            isNeedShowAgain
            title={t('Request:Attention')}
            text={t('Request:UnfinishedActivitiesWarning')}
            isAsync={false}
            confirm='Ok'
            onToggleCheck={onToggleCheck}
            onOk={onAccept}
            onClose={onClose}
          />
        </Modal>
      )}
      {isOpenPostsModal && (
        <Modal isOpen>
          <ConfirmationPopup
            title={t('Request:Attention')}
            text={t('Request:OnlinePostsWarning', {
              count: onlinePostsArr.length,
            })}
            isAsync={false}
            cancel={t('Common:no')}
            confirm={t('Common:yes')}
            onOk={() => onAcceptPosts(true)}
            onCancel={onAcceptPosts}
            onClose={onClosePosts}
          />
        </Modal>
      )}
    </>
  )
}

export default StatusSelect
