// @flow

import React, { useEffect, useState } from 'react'
import type { Node } from 'react'
import { useTranslation } from 'react-i18next'

import ModalRow from '../../Modal/ModalRow'
import InputField from '../../InputField'
import Checkbox from '../../Checkbox/Checkbox'
import NewSelectSimple from '../../NewSelectSimple'

import Conditions from '../Conditions'

import classnames from 'classnames'

import {
  getDeadlineTemplate,
  getDeadlineObject,
} from '../../Activities/ActivityModal/ActivityModal.utils'

import { ADDITIONAL_CONDITION_FIELDS } from '../AutomateTable/Automate.constants'

import { DEADLINE_REGEXP } from '../../Activities/ActivityModal/ActivityModal.constants'

import styles from '../AutomateInfo.module.scss'

type Props = {
  condition: Object,
  data: Object,
  onUpdate: Object => void,
}

const SearchDuplicates = (props: Props): Node => {
  const { condition, data, onUpdate } = props
  const { t } = useTranslation('RequestConstructor')

  const [createdTemplate, setCreatedTemplate] = useState(
    getDeadlineTemplate(
      data.created_period_days,
      data.created_period_hours,
      data.created_period_minutes
    )
  )
  const [copyCondition, setCopyCondition] = useState({})

  const [periodError, setPeriodError] = useState(false)

  const cardClass = classnames(
    'request-constructor__card--body request-constructor__card--email'
  )

  const postProccessOptions = [
    { label: t('ConnectToRequest'), value: 'connect' },
  ]

  useEffect(() => {
    onUpdate({
      additional_condition: data.additional_condition || {},
      created_period_days: data.created_period_days || null,
      created_period_hours: data.created_period_hours || null,
      created_period_minutes: data.created_period_minutes || null,
      post_process_action: data.post_process_action || 'connect',
      same_building:
        data.same_building === undefined ? true : data.same_building,
      same_category: data.same_category || false,
    })
  }, [])

  useEffect(() => {
    const validPeriod =
      !createdTemplate || DEADLINE_REGEXP.test(createdTemplate)
    setPeriodError(!validPeriod)
  }, [createdTemplate])

  useEffect(() => {
    setCopyCondition(getConditionForCopy())
  }, [condition])

  const handleChangePeriod = e => {
    const created = e.target.value
    setCreatedTemplate(created)
    const deadline_object = getDeadlineObject(created)
    const created_object = {
      created_period_days: deadline_object.deadline_days,
      created_period_hours: deadline_object.deadline_hours,
      created_period_minutes: deadline_object.deadline_minutes,
    }
    props.onUpdate({ ...data, ...created_object })
  }

  const handleChangeSameBuilding = e =>
    props.onUpdate({ ...data, same_building: e.currentTarget.checked })

  const handleChangeSameCategory = e =>
    props.onUpdate({ ...data, same_category: e.currentTarget.checked })

  const getConditionForCopy = () => {
    if (Object.keys(condition).length > 1) {
      return ADDITIONAL_CONDITION_FIELDS.indexOf(condition.field) === -1
        ? {}
        : condition
    } else {
      return {
        [Object.keys(condition)[0]]: condition[
          Object.keys(condition)[0]
        ].filter(c => ADDITIONAL_CONDITION_FIELDS.indexOf(c.field) !== -1),
      }
    }
  }

  return (
    <div className={cardClass}>
      <ModalRow>{t('DuplicateConditions')}</ModalRow>
      <ModalRow>{t('CreatedPeriod')}</ModalRow>
      <ModalRow>
        <InputField
          className={styles.periodInput}
          error={periodError && t('WrongDeadlineFormat')}
          name={'created_template'}
          value={createdTemplate}
          placeholder='4d 6h 45m'
          onChange={handleChangePeriod}
        />
      </ModalRow>
      <ModalRow className={styles.searchRow}>
        <Checkbox
          style={{ marginRight: '8px' }}
          checked={data.same_building}
          onChange={handleChangeSameBuilding}
        />
        <span className={styles.toggleText}>{t('SameBuilding')}</span>
      </ModalRow>
      <ModalRow className={styles.searchRow}>
        <Checkbox
          style={{ marginRight: '8px' }}
          checked={data.same_category}
          onChange={handleChangeSameCategory}
        />
        <span className={styles.toggleText}>{t('SameCategory')}</span>
      </ModalRow>
      <ModalRow className={styles.additionalConditionRow}>
        <Conditions
          additional
          canEdit
          condition={data.additional_condition || {}}
          copyCondition={copyCondition}
          setCondition={additional_condition =>
            props.onUpdate({ ...data, additional_condition })
          }
          trigger={'request.created'}
        />
      </ModalRow>
      <ModalRow className={styles.searchRow}>
        {t('ActionForDuplicates')}
      </ModalRow>
      <ModalRow>
        <NewSelectSimple
          disabled
          className={styles.searchSelect}
          value={postProccessOptions[0]}
          options={postProccessOptions}
          onChange={() => {}}
        />
      </ModalRow>
    </div>
  )
}

export default SearchDuplicates
