// @flow

import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import Loader from '../Loader'
import { redirectTo404 } from '../../utils/routing'

import { getFormattedDate, getUrlForAvatar } from '../../utils/utils'
import { SimpleBlock, Header, CellsBar, Cell, Row } from '../Block'
import Icon from '../Icon'
import Button from '../Button'
import TableCellUsers from '../TableCellUsers'
import Modal from '../Modal'
import ConfirmationPopup from '../modals/ConfirmationPopup'
import TitleModal from './TitleModal'
import AddKeyModal from './AddKeyModal'

import SelectUser from '../Select/SelectUser'
import ValueItem from '../Select/ValueItem'
import SelectCustom from '../Select/SelectCustom'

import {
  deleteInboundConfigProfile,
  getInboundConfigProfile,
  updateInboundConfigProfile,
} from '../../core/api/api.idwell2idwell'

import styles from './ConnectedCompanies.module.scss'

const InboundCompanyProfile = () => {
  const { t } = useTranslation('IdwellToIdwell')
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)
  const [company, setCompany] = useState(null)
  const [editTitle, setEditTitle] = useState(false)
  const [editUser, setEditUser] = useState(false)
  const [user, setUser] = useState(null)
  const [modal, setModal] = useState(null)

  const match = useRouteMatch()
  const uuid = match.params.uuid

  useEffect(() => {
    if (loading && uuid) {
      getInboundConfigProfile(uuid)
        .then(data => {
          setCompany(data)
          setUser(data.inbound_user_obj)
        })
        .catch(error => redirectTo404(error))
        .finally(() => setLoading(false))
    }
  }, [loading])

  const handleUpdateName = name => {
    updateInboundConfigProfile(uuid, { name }).then(() => {
      setLoading(true)
      setEditTitle(false)
    })
  }

  const handleConfirmGenerateKey = () => {
    setModal(
      <ConfirmationPopup
        title={t('GenerateKeyTitle')}
        text={t('GenerateKeyText')}
        confirm={t('Generate')}
        cancel={t('Common:Cancel')}
        onClose={handleCloseModal}
        onOk={handleGenerateKey}
      />
    )
  }

  const handleGenerateKey = () => {
    updateInboundConfigProfile(uuid, { regenerate_key: true }).then(data => {
      setModal(
        <AddKeyModal
          isOpen
          activeKey={data.active_key}
          onClose={() => {
            handleCloseModal()
            setLoading(true)
          }}
        />
      )
    })
  }

  const handleConfirmUpdateUser = () => {
    setModal(
      <ConfirmationPopup
        title={t('UpdateUserModalTitle')}
        text={t('UpdateUserModalText')}
        confirm={t('Confirm')}
        cancel={t('Common:Cancel')}
        onClose={handleCloseModal}
        onOk={handleUpdateUser}
      />
    )
  }

  const handleUpdateUser = () => {
    updateInboundConfigProfile(uuid, { inbound_user: user.id }).then(() => {
      handleCloseModal()
      setLoading(true)
      setEditUser(false)
    })
  }

  const handleConfirmDeleteKey = () => {
    setModal(
      <ConfirmationPopup
        title={t('DeleteKeyModalTitle')}
        text={t('DeleteKeyModalText')}
        confirm={t('Delete')}
        cancel={t('Common:Cancel')}
        onClose={handleCloseModal}
        onOk={handleDeleteKey}
      />
    )
  }

  const handleDeleteKey = () => {
    updateInboundConfigProfile(uuid, {
      expiration_date: moment().format('YYYY-MM-DDTHH:mm:ss'),
    }).then(() => {
      handleCloseModal()
      setLoading(true)
    })
  }

  const handleConfirmDeleteCompany = () => {
    setModal(
      <ConfirmationPopup
        title={t('DeleteTitle')}
        text={t('ConfirmDeleteOne')}
        confirm={t('Delete')}
        cancel={t('Common:Cancel')}
        onClose={handleCloseModal}
        onOk={handleDeleteCompany}
      />
    )
  }

  const handleDeleteCompany = () => {
    deleteInboundConfigProfile(uuid).then(() => {
      dispatch(push('/settings/idwell-to-idwell'))
    })
  }

  const handleCloseModal = () => setModal(null)

  const moreOptions = [
    { value: 'deleteKey', label: t('DeleteKey') },
    { value: 'deleteCompany', label: t('DeleteCompany') },
  ]

  const handleMoreAction = ({ value }) => {
    switch (value) {
      case 'deleteKey':
        handleConfirmDeleteKey()
        break
      case 'deleteCompany':
        handleConfirmDeleteCompany()
        break
    }
  }

  const updateUser = user => {
    setUser(user)

    if (!editUser) {
      setEditUser(true)
    }
  }

  if (loading) {
    return <Loader text={false} type='big' />
  }

  return (
    <div className='content__col'>
      <SimpleBlock>
        <Header header textTitle={company.name} className={styles.header}>
          <div icon='true' className={styles.icon}>
            <Icon id='connect' />
          </div>
          <span className={styles.name}>{company.name}</span>
          {!editTitle && <Button.Edit onClick={() => setEditTitle(true)} />}
        </Header>
        <CellsBar>
          <Cell title={t('Created')}>{getFormattedDate(company.created)}</Cell>
          <Cell
            title={t('Creator')}
            className={styles.headerUserCell}
            valueClassName={styles.headerUser}
          >
            <TableCellUsers items={[company.created_by_obj]} />
          </Cell>
          <Cell title={t('LastUpdate')}>
            {getFormattedDate(company.updated)}
          </Cell>
        </CellsBar>
        <Row
          title={t('PartnerUser')}
          onEdit={!editUser ? () => setEditUser(true) : null}
        >
          {user ? (
            <>
              <ValueItem
                inline
                profile
                round
                cleanable={editUser}
                avatar={getUrlForAvatar(user)}
                profileId={user.id}
                text={user.fullname}
                onRemove={() => setUser(null)}
              />
            </>
          ) : (
            <SelectUser
              round
              isDweller
              cleanable
              params={{ status: 'active' }}
              onChange={updateUser}
            />
          )}
        </Row>
        <Row>
          <div className={styles.buttonWrapper}>
            {!editUser && (
              <>
                <Button.Save onClick={handleConfirmGenerateKey}>
                  {t('GenerateKey')}
                </Button.Save>
                <SelectCustom
                  alignLeft
                  buttonClass='button button--large button--dropdown button--showmore '
                  options={moreOptions}
                  onChange={handleMoreAction}
                >
                  {t('ShowMore')}
                </SelectCustom>
              </>
            )}
            {editUser && (
              <>
                <Button.Save
                  disabled={!user || user?.id === company.inbound_user_obj?.id}
                  onClick={handleConfirmUpdateUser}
                >
                  {t('Common:Save')}
                </Button.Save>
                <Button.Cancel
                  onClick={() => {
                    setEditUser(false)
                    setUser(company.inbound_user_obj)
                  }}
                >
                  {t('Common:Cancel')}
                </Button.Cancel>
              </>
            )}
          </div>
        </Row>
      </SimpleBlock>
      {editTitle && (
        <TitleModal
          updateTitle={handleUpdateName}
          name={company.name}
          onClose={() => setEditTitle(false)}
        />
      )}
      {!!modal && (
        <Modal isOpen onRequestClose={handleCloseModal}>
          {modal}
        </Modal>
      )}
    </div>
  )
}

export default InboundCompanyProfile
