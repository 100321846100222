// @flow

import React, { useState, useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Loader from '../Loader'
import { redirectTo404 } from '../../utils/routing'

import RouterTabs from '../Tabs/RouterTabs'
import Pan from '../Tabs/Pan'
import { SimpleBlock, Header } from '../Block'

import AutomateInfo from './AutomateInfo'
import AutomateConstructor from './AutomateConstructor'
import AutomateHistory from './AutomateHistory'

import { getAutomation } from '../../core/api/api.automate'

const AutomateView = () => {
  const match = useRouteMatch()
  const id = match.params.id
  const { t } = useTranslation('Automate')

  const [loading, setLoading] = useState(true)
  const [automate, setAutomate] = useState(null)
  const [logs, setLogs] = useState(0)

  useEffect(() => {
    if (loading && id) {
      getAutomation(id)
        .then(data => setAutomate(data))
        .catch(error => redirectTo404(error))
        .finally(() => setLoading(false))
    }
  }, [loading])

  if (loading) {
    return <Loader text={false} type='big' />
  }

  return (
    <div className='content__col'>
      <AutomateInfo automate={automate} setLoading={setLoading} />
      <RouterTabs
        openFirst='constructor'
        className='tabs tabs-style-transparent'
      >
        <Pan id='constructor' label={t('Constructor')} linkHash='#constructor'>
          <AutomateConstructor
            id={automate?.id}
            condition={automate?.condition || {}}
            canEdit={automate?.permissions?.can_edit}
            setLoading={setLoading}
            trigger={automate?.trigger_event}
          />
        </Pan>
        <Pan
          id='automate-history'
          label={`${t('AutomationHistory')} ${logs || ''}`}
          linkHash='#automate-history'
        >
          <SimpleBlock>
            <Header header>{t('AutomationHistory')}</Header>
            <AutomateHistory setCount={setLogs} id={automate.id} />
          </SimpleBlock>
        </Pan>
      </RouterTabs>
    </div>
  )
}

export default AutomateView
