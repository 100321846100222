// @flow

// Condition types
export const EQUAL = 'equals'
export const CONTAIN = 'contains'
export const NOT_CONTAIN = 'not_contain'
export const NOT_EMPTY = 'not_empty'

export const MAX_LINKS_COUNT = 20
export const MAX_VARIABLES_COUNT = 20

export const ButtonTypes = {
  text: 'text',
  image: 'image',
  variable: 'variable-input',
  filter: 'auto-redirect',
  email: 'email-sender',
  request: 'request-maker',
}

export const NewButtonTypes = {
  email: 'email-sender',
  activity: 'activity',
  request: 'request-maker',
  post: 'post',
  phase: 'phase',
  text: 'text',
  archive: 'archive',
  search: 'search',
}

export const ActionTypes = {
  activity: 'create_activity',
  'email-sender': 'send_email',
  phase: 'start_phase',
  'request-maker': 'create_request',
  post: 'create_post',
  search: 'search_request_duplicates',
}

export const LinkButtonTypes = [ButtonTypes.text, ButtonTypes.image]

export const VariableTypes = {
  string: 'string',
  text: 'text',
  phone: 'phone',
  email: 'email',
  file: 'file',
  integer: 'integer',
}
