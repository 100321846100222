// @flow

import { all, put, call, fork, takeEvery } from 'redux-saga/effects'
import { replace } from 'connected-react-router'
import type { Saga } from 'redux-saga'

import api from '../../core/api'
import { serverError, modalError } from '../Layout/Layout.actions'
import * as actions from './BuildingCreate.actionTypes'

function* watchInit() {
  yield takeEvery(actions.BUILDING_CREATE_INITIATNG, init)
}

function* watchSave() {
  yield takeEvery(actions.BUILDING_CREATE_SAVE, save)
}

function* init() {
  try {
    const { permissions } = yield call(api.building.getList)
    yield put({ type: actions.BUILDING_CREATE_INITIATED, permissions })
  } catch (error) {
    yield put(serverError(error))
    yield put({ type: actions.BUILDING_CREATE_ERROR, error })
  }
}

function* save({
  address,
  building_groups,
  update_responsible_users,
  isPromo,
}) {
  try {
    const data = { address: address.id }

    if (data.address) {
      yield call(api.address.update, address)
    } else {
      const result = yield call(api.address.add, address)
      data.address = result.id
    }

    if (isPromo) {
      data.is_promo = true
    }

    if (building_groups.length > 0) {
      data.building_groups = building_groups
    }

    if (update_responsible_users) {
      data.update_responsible_users = true
    }

    const building = yield call(api.building.createBuilding, data)

    if (building.update_building_owners_by_building_group_exceed_limits) {
      yield put({ type: actions.BUILDING_SHOW_MANAGERS_LIMIT, id: building.id })
    } else if (!building.error) {
      yield put(replace(`/building/${building.id}`))
    }
  } catch (error) {
    if (error.message.response.status === 400) {
      yield put(modalError(error.message.response.data))
      yield put({ type: actions.BUILDING_CREATE_ERROR, error })

      return
    }

    yield put(serverError(error))
  }
}

export default function* watch(): Saga<void> {
  yield all([fork(watchInit), fork(watchSave)])
}
