// @flow

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isArray } from 'lodash-es'
import type { Node } from 'react'

import Button from '../Button'
import Checkbox from '../Checkbox'
import { useOverflow } from '../../hooks/useOverflow'
import ModalBody from '../Modal/ModalBody'
import ModalRow from '../Modal/ModalRow/ModalRow'
import ModalCloseButton from '../Modal/ModalCloseButton'
import ModalButtons from '../Modal/ModalButtons'
import ModalHead from '../Modal/ModalHead'

type Props = {
  cancel?: string,
  confirm?: string,
  isAsync: boolean,
  isNeedShowAgain?: boolean,
  onCancel?: () => void,
  onClose: () => void,
  onOk: () => any,
  onToggleCheck?: (isChecked: boolean) => any,
  showCancel: boolean,
  showOk: boolean,
  text?: string | Array<string> | boolean,
  title: string,
}

const ConfirmationPopup = (props: Props): Node => {
  const {
    title,
    text,
    confirm,
    cancel,
    showCancel,
    showOk,
    isAsync,
    onToggleCheck,
    isNeedShowAgain = false,
  } = props

  const [working, setWorking] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  const { t } = useTranslation('Modal')

  useOverflow()

  useEffect(() => {
    if (working) {
      props.onOk()
    }
  }, [working])

  useEffect(() => {
    if (isNeedShowAgain && onToggleCheck) {
      onToggleCheck(isChecked)
    }
  }, [isChecked])

  const message = text !== false ? text || t('ConfirmationText') : ''

  const html = {
    __html: isArray(message) ? message.join('') : message,
  }

  const handleOk = () => {
    if (isAsync) {
      setWorking(true)
    } else {
      props.onOk()
    }
  }

  const handleCancel = () => {
    if (props.onCancel) {
      props.onCancel()
    } else {
      props.onClose()
    }
  }

  const handleChange = () => {
    setIsChecked(prevVal => !prevVal)
  }

  return (
    <>
      <ModalCloseButton onClose={props.onClose} />
      <ModalHead title={title} />
      <ModalBody>
        <ModalRow>
          <p className='modal__text' dangerouslySetInnerHTML={html} />
        </ModalRow>
        {isNeedShowAgain && (
          <ModalRow>
            <div style={{ marginTop: 10 }}>
              <Checkbox
                checked={isChecked}
                text={t('Mail:DoNotShow')}
                onChange={handleChange}
              />
            </div>
          </ModalRow>
        )}
      </ModalBody>
      <ModalButtons>
        {showOk && (
          <Button.Save working={working} onClick={handleOk}>
            {confirm || t('Delete')}
          </Button.Save>
        )}
        {showCancel && (
          <Button.Cancel disabled={working} onClick={handleCancel}>
            {cancel || t('Cancel')}
          </Button.Cancel>
        )}
      </ModalButtons>
    </>
  )
}

ConfirmationPopup.defaultProps = {
  title: '',
  showOk: true,
  showCancel: true,
  isAsync: true,
}

export default ConfirmationPopup
