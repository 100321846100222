// @flow

import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { connect, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import type { Node } from 'react'

import EmptyList from '../../components/EmptyList'
import Loader from '../../components/Loader'
import NewsFeedPost from '../../components/Post/NewsFeedPost'
import { INF_SCROLL_PX_THRESHOLD } from '../../constants'
import * as actions from './NewsList.actions'
import * as selectors from './NewsList.selectors'

import styles from './NewsList.module.scss'

type Props = {
  additionalQueryParams: Object,
  dontReset: boolean,
  emptyBoxTitle?: string,
  hasMore: boolean,
  initiating: boolean,
  newsListInitiating: Object => void,
  newsListLoadMoreInitiating: Object => void,
  newsListReset: Object => void,
  posts: Array<Object>,
  postsCount: number,
  postsReloading?: boolean,
}

const NewsList = (props: Props): Node => {
  const { initiating, additionalQueryParams, dontReset, postsReloading } = props

  const { t } = useTranslation('Building')

  const [scrollParams, setScrollParams] = useState(null)

  const requestStatuses = useSelector(state => state.init.request_status)

  const handleScroll = () => {
    setScrollParams({
      innerHeight: window.innerHeight,
      scrollY: window.scrollY,
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    if (!dontReset) {
      props.newsListInitiating(additionalQueryParams)
    }

    return function cleanup() {
      if (!dontReset) {
        props.newsListReset()
      }

      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (postsReloading) {
      props.newsListInitiating(additionalQueryParams)
    }
  }, [postsReloading])

  if (initiating) {
    return <Loader text={false} type='big' />
  }

  const { posts, hasMore, postsCount, emptyBoxTitle, loadingMore } = props
  const loader = <Loader key='loader' type='big' text={false} />
  const loadMore = () => {
    !loadingMore && props.newsListLoadMoreInitiating(additionalQueryParams)
  }

  if (!postsCount) {
    const boxClass = classnames(styles['empty-box'], {
      [styles['empty-box-embedded']]: Boolean(emptyBoxTitle),
    })

    return (
      <div className={boxClass}>
        {Boolean(emptyBoxTitle) && (
          <h2 className='unit__title'>{emptyBoxTitle}</h2>
        )}
        <EmptyList icon='post' title={t('ThereAreNoPostsYet')} />
      </div>
    )
  }

  return (
    <InfiniteScroll
      useWindow
      loadMore={loadMore}
      hasMore={hasMore}
      loader={loader}
      threshold={INF_SCROLL_PX_THRESHOLD}
      initialLoad={false}
    >
      {posts.map(post => (
        <NewsFeedPost
          key={post.uuid}
          post={post}
          scrollParams={scrollParams}
          requestStatuses={requestStatuses}
        />
      ))}
    </InfiniteScroll>
  )
}

NewsList.defaultProps = {
  additionalQueryParams: {},
  dontReset: false,
}

const mapStateToProps = state => ({
  posts: selectors.getPosts(state),
  hasMore: selectors.hasMore(state),
  loadingMore: selectors.loadingMore(state),
  initiating: selectors.initiating(state),
  postsCount: selectors.getPostCount(state),
})

const mapDispatchToProps = {
  newsListInitiating: actions.newsListInitiating,
  newsListLoadMoreInitiating: actions.newsListLoadMoreInitiating,
  newsListReset: actions.newsListReset,
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsList)
