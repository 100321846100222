// @flow

import React, { useState, useEffect, useRef } from 'react'
import type { Node } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { globalModalError } from '../Layout/Layout.actions'

import { isEmpty, isEqual, cloneDeep, map, omit } from 'lodash-es'

import {
  updateAutomation,
  getAutomationActions,
  addAutomationAction,
  updateAutomationAction,
} from '../../core/api/api.automate'

import {
  parseActions,
  validateAction,
  validateCondition,
} from './Automate.utils'
import {
  OMIT_FIELDS,
  TRIGGER_ACTIVITY_UPDATED,
  TRIGGER_REQUEST_CREATED,
  EMPTY_CONDITION,
} from './AutomateTable/Automate.constants'

import { SimpleBlock, Header } from '../Block'
import EmptyList from '../EmptyList'
import Modal from '../Modal'
import ConfirmationPopup from '../modals/ConfirmationPopup'
import Conditions from './Conditions'
import AutomateActions from './AutomateActions'

import Button from '../Button'
import InputField from '../InputField'

import { NewButtonTypes, ActionTypes } from '../RequestConstructor/constants'
import CardIcon from '../RequestConstructor/CardIcon'
import CardArrow from '../RequestConstructor/CardArrow'

import styles from './AutomateInfo.module.scss'

type Props = {
  canEdit: boolean,
  condition: Object,
  id: number,
  setLoading: boolean => void,
  trigger: string,
}

const DISABLED_ACTIONS = {
  [TRIGGER_ACTIVITY_UPDATED]: ['text', 'archive', 'search'],
  [TRIGGER_REQUEST_CREATED]: [
    'text',
    'archive',
    'activity',
    'phase',
    'request-maker',
  ],
}

const AutomateConstructor = (props: Props): Node => {
  const { id, canEdit, trigger } = props
  const { t } = useTranslation('Automate')
  const dispatch = useDispatch()

  const [condition, setCondition] = useState(cloneDeep(props.condition))
  const [initialActions, setInitialActions] = useState([])
  const [actions, setActions] = useState([])
  const [saveModal, setSaveModal] = useState(false)
  const [removeModal, setRemoveModal] = useState(null)
  const [working, setWorking] = useState(false)
  const needUpdateActionIds = useRef(false)

  const normalizedCondition = isEqual(condition, EMPTY_CONDITION)
    ? {}
    : condition

  useEffect(() => {
    getAutomationActions(id).then(data => {
      setInitialActions([...parseActions(data)])
      setActions([...parseActions(data)])

      if (data.length > 0 && isEmpty(condition)) {
        setCondition(EMPTY_CONDITION)
      }
    })
  }, [])

  const saveAutomation = action_ids => {
    if (needUpdateActionIds.current || actions.length < initialActions.length) {
      updateAutomation(id, { action_ids, condition: normalizedCondition })
        .then(() => props.setLoading(true))
        .catch(err => handleSaveError(err))
    } else {
      updateAutomation(id, { condition: normalizedCondition })
        .then(() => props.setLoading(true))
        .catch(err => handleSaveError(err))
    }
  }

  const handleAddEmptyCondition = () => {
    setCondition(EMPTY_CONDITION)
  }

  const handleAddAction = type => {
    const newAction = {
      id: -1 * (actions.length + 1),
      action_type: ActionTypes[type],
      action_data:
        type === 'activity'
          ? {
              priority_id: null,
              deadline_days: null,
              deadline_hours: null,
              deadline_minutes: null,
              notes: null,
            }
          : {},
    }
    setActions([...actions, newAction])
  }

  const handleUpdateAction = (actionId, data) => {
    let i = actions.findIndex(a => a.id === actionId)
    let newActions = [...actions]
    newActions[i].action_data = data
    setActions(newActions)
  }

  const handleRemoveAction = actionId => {
    setRemoveModal(
      <ConfirmationPopup
        confirm={t('Delete')}
        title={t('ConfirmRemoveActionTitle')}
        text={t('ConfirmRemoveActionText')}
        onClose={() => setRemoveModal(null)}
        onOk={() => confirmRemoveAction(actionId)}
      />
    )
  }

  const confirmRemoveAction = actionId => {
    let i = actions.findIndex(a => a.id === actionId)
    let newActions = [...actions]
    newActions.splice(i, 1)
    setActions(newActions)
    setRemoveModal(null)
  }

  const hideSaveModal = () => setSaveModal(false)

  const handleSave = () => {
    if (!isEqual(initialActions, actions)) {
      saveAction()
    } else {
      updateAutomation(id, { condition: normalizedCondition })
        .then(() => props.setLoading(true))
        .catch(err => handleSaveError(err))
    }
  }

  const saveAction = (i = 0, action_ids = []) => {
    if (actions.length > 0) {
      const action = actions[i]

      if (!action.id || action.id < 0) {
        needUpdateActionIds.current = true
        delete action.id
        addAutomationAction(id, action)
          .then(data => {
            saveActionCallback(i, action_ids, data.id)
          })
          .catch(err => handleSaveError(err))
      } else {
        const { action_data } = action
        const oldAction = initialActions.find(a => a.id === action.id)

        if (!isEqual(action, oldAction)) {
          updateAutomationAction(id, action.id, { ...action_data })
            .then(() => {
              saveActionCallback(i, action_ids, action.id)
            })
            .catch(err => handleSaveError(err))
        } else {
          saveActionCallback(i, action_ids, action.id)
        }
      }
    } else {
      saveAutomation(action_ids)
    }
  }

  const saveActionCallback = (i, action_ids, action_id) => {
    action_ids.push(action_id)

    if (i === actions.length - 1) {
      saveAutomation(action_ids)
    } else {
      saveAction(i + 1, action_ids)
    }
  }

  const isSaveDisabled = () => {
    if (!canEdit) {
      return true
    }

    if (working) {
      return true
    }

    if (isCancelDisabled()) {
      return true
    }

    for (let i = 0; i < actions.length; i++) {
      if (!validateAction(actions[i])) {
        return true
      }
    }

    if (!validateCondition(condition)) {
      return true
    }

    return false
  }

  const handleSaveError = err => {
    dispatch(
      globalModalError(
        err.message?.response?.data?.errors || 'Automate:ErrorSave',
        '',
        true
      )
    )
    hideSaveModal()
  }

  const handleCancel = () => props.setLoading(true)

  const isCancelDisabled = () => {
    let equalCondition = false

    if (isEqual(condition, props.condition)) {
      equalCondition = true
    }

    if (isEqual(condition, EMPTY_CONDITION)) {
      equalCondition = isEqual(props.condition, {})
    }

    const equalActions = isEqual(
      actions,
      initialActions.map(a => ({
        ...a,
        action_data: omit(a.action_data, OMIT_FIELDS),
      }))
    )

    return equalCondition && equalActions
  }

  const renderConditions = () => {
    if (isEmpty(condition)) {
      return (
        <EmptyList
          embedded
          canAdd={canEdit}
          btnText={t('AddCondition')}
          icon='automate'
          title={t('EmptyCondition')}
          onClick={handleAddEmptyCondition}
        />
      )
    }

    return (
      <>
        {canEdit && (
          <div className={styles.actions}>
            <div className={styles.actionsCaption}>{t('AddAction')}:</div>
            <div className={styles.actionCards}>
              {map(NewButtonTypes, type => (
                <CardIcon
                  automate
                  key={type}
                  type={type}
                  disabled={DISABLED_ACTIONS[trigger].includes(type)}
                  onClick={() => handleAddAction(type)}
                />
              ))}
            </div>
          </div>
        )}
        <div
          className={styles.triggerCard}
          style={{ margin: '10px auto 0 auto' }}
        >
          <div className={styles.triggerTitle}>{t('Trigger')}</div>
          <div className={styles.triggerBody}>
            <div>{t('Trigger')}</div>
            <InputField
              disabled
              name='trigger'
              value={t(`Trigger.${trigger}`)}
            />
          </div>
        </div>
        <CardArrow wide />
        <div className={styles.conditionCard}>
          <div className={styles.conditionTitle}>{t('Condition')}</div>
          <Conditions
            canEdit={canEdit}
            condition={condition}
            setCondition={setCondition}
            trigger={trigger}
          />
        </div>
        {actions.length > 0 && (
          <AutomateActions
            actions={actions}
            canEdit={canEdit}
            condition={condition}
            setWorking={setWorking}
            onUpdate={handleUpdateAction}
            onRemove={handleRemoveAction}
          />
        )}
      </>
    )
  }

  return (
    <>
      <SimpleBlock>
        <Header header textTitle={t('AutomationProcesses')}>
          <span>{t('AutomationProcesses')}</span>
        </Header>
        {renderConditions()}
        <div className={styles.buttons}>
          <Button.Save
            disabled={isSaveDisabled()}
            onClick={() => setSaveModal(true)}
          >
            {t('Common:Save')}
          </Button.Save>
          <Button.Cancel disabled={isCancelDisabled()} onClick={handleCancel}>
            {t('Common:Cancel')}
          </Button.Cancel>
        </div>
      </SimpleBlock>
      {saveModal && (
        <Modal isOpen onRequestClose={hideSaveModal}>
          <ConfirmationPopup
            isAsync
            confirm={t('Common:Save')}
            title={t('ConfirmSaveTitle')}
            text={t('ConfirmSaveText')}
            onClose={hideSaveModal}
            onOk={handleSave}
          />
        </Modal>
      )}
      {removeModal && (
        <Modal isOpen onRequestClose={() => setRemoveModal(null)}>
          {removeModal}
        </Modal>
      )}
    </>
  )
}

export default AutomateConstructor
