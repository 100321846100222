// @flow

export const BUILDING_CREATE_INITIATNG = 'BUILDING_CREATE_INITIATNG'
export const BUILDING_CREATE_INITIATED = 'BUILDING_CREATE_INITIATED'
export const BUILDING_CREATE_SAVE = 'BUILDING_CREATE_SAVE'
export const BUILDING_CREATE_ERROR = 'BUILDING_CREATE_ERROR'
export const BUILDING_HIDE_EROR = 'BUILDING_HIDE_EROR'
export const BUILDING_SHOW_CONFIRM = 'BUILDING_SHOW_CONFIRM'
export const BUILDING_HIDE_CONFIRM = 'BUILDING_HIDE_CONFIRM'
export const BUILDING_SHOW_MANAGERS_LIMIT = 'BUILDING_SHOW_MANAGERS_LIMIT'
export const BUILDING_HIDE_MANAGERS_LIMIT = 'BUILDING_HIDE_MANAGERS_LIMIT'
