// @flow

import React, { useMemo } from 'react'
import { useRouteMatch, Link } from 'react-router-dom'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import type { Node } from 'react'

import {
  getRedesignUiSectionsConfig,
  getUkConfig,
} from '../../utils/commonSelectors'

import { getRedesignLinks } from './CustomNavLink.utils'

const CustomNavLink = (props): Node => {
  const { to, activeClassName, children, exact, isActive } = props

  const ukConfig = useSelector(getUkConfig)
  const redesignSections = useSelector(getRedesignUiSectionsConfig)

  const match = useRouteMatch({
    path: to,
    exact: exact,
  })

  const className = classnames({ [activeClassName]: isActive(match) })

  const redesignLinks = useMemo(
    () => getRedesignLinks(redesignSections),
    [redesignSections]
  )

  const redesignLink = to === '/' ? '/newsboard' : to

  if (ukConfig?.is_new_design_available && redesignLinks.includes(to)) {
    return (
      <a href={`/redesign${redesignLink}`} className={className}>
        {children}
      </a>
    )
  }

  return (
    <Link to={to} className={className} onClick={props.onClick}>
      {children}
    </Link>
  )
}

export default CustomNavLink
