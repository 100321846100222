// @flow

import { getApi, postApi, deleteApi, patchApi } from './api.core'

export const getChatrooms = params => getApi(`api/chatroom/`, params)
export const getMessages = (
  chatroomUuid,
  limit,
  messageUuid,
  isGetOld,
  page,
  outbound
) =>
  getApi(
    `api/${
      outbound ? `id2id/${outbound}/` : ''
    }chatroom/${chatroomUuid}/messages/?limit=${limit}${
      messageUuid ? `&from=${messageUuid}` : ''
    }${isGetOld ? '&get_prev=1' : ''}${page ? '&page=' + page : ''}`
  )
export const getArchivedInfo = (chatroomUuid, messageUuid, pageSize) =>
  getApi(
    `api/chatroom/${chatroomUuid}/archived_info/?from=${messageUuid}&page_size=${pageSize}`
  )
export const readMessages = (chatroomUuid, outbound) =>
  postApi(
    `api/${outbound ? `id2id/${outbound}/` : ''}chatroom/${chatroomUuid}/read/`
  )
export const getMembers = (uuid, outbound) =>
  getApi(`api/${outbound ? `id2id/${outbound}/` : ''}chatroom/${uuid}/members/`)
export const addMembers = (uuid, params) =>
  postApi(`api/chatroom/${uuid}/members/`, params)
export const removeMembers = (uuid, users) =>
  deleteApi(`api/chatroom/${uuid}/members/?ids=${users}`)
export const getChatroomInfo = (uuid, outbound) =>
  getApi(`api/${outbound ? `id2id/${outbound}/` : ''}chatroom/${uuid}/`)
export const getChatroomFiles = uuid => getApi(`api/chatroom/${uuid}/files/`)
export const createChatroom = ({ outbound, ...params }) =>
  postApi(`api/${outbound ? `id2id/${outbound}/` : ''}chatroom/`, params)
export const sendMessage = ({ outbound, ...params }) =>
  postApi(`api/${outbound ? `id2id/${outbound}/` : ''}pm/`, params)
export const updateChatroom = (uuid, params) =>
  patchApi(`api/chatroom/${uuid}/`, params)
export const addLikeToMessage = (uuid, outbound = null) =>
  postApi(`api/${outbound ? `id2id/${outbound}/` : ''}pm/${uuid}/like/`)
export const addDislikeToMessage = (uuid, outbound = null) =>
  postApi(`api/${outbound ? `id2id/${outbound}/` : ''}pm/${uuid}/dislike/`)
export const removeDislikeFromMessage = (uuid, outbound = null) =>
  deleteApi(`api/${outbound ? `id2id/${outbound}/` : ''}pm/${uuid}/dislike/`)
export const deleteMessage = (uuid, outbound = null) =>
  deleteApi(`api/${outbound ? `id2id/${outbound}/` : ''}pm/${uuid}/`)
export const updateMessage = (uuid, params, outbound = null) =>
  patchApi(`api/${outbound ? `id2id/${outbound}/` : ''}pm/${uuid}/`, params)
export const getCounters = () => getApi('api/chatroom/counters/')
