// @flow

import {
  DONE_COMMIT_TYPE,
  YESNO_COMMIT_TYPE,
} from '../Activities/ActivityModal/ActivityModal.constants'

import { EMPTY_CONDITION } from './AutomateTable/Automate.constants'

import { isEmpty, isEqual } from 'lodash-es'

export const parseActions = actions => actions.map(parseAction)

const parseAction = a => {
  switch (a.action_type) {
    case 'send_email':
      return {
        id: a.id,
        action_type: a.action_type,
        action_data: {
          audience: a.action_data.audience,
          attach_option: a.action_data.attach_option,
          bcc: a.action_data.bcc,
          cc: a.action_data.cc,
          files: a.action_data.files,
          files_as_links: a.action_data.files_as_links,
          files_options: a.action_data.files_options || [],
          file_ids: a.action_data.files.map(f => f.id),
          reply_to_mail_account_id:
            a.action_data.reply_to_mail_account?.id || null,
          send_by_mail_account_id:
            a.action_data.send_by_mail_account?.id || null,
          send_by_system_credentials: a.action_data.send_by_system_credentials,
          subject: a.action_data.subject,
          text: a.action_data.text,
          to: a.action_data.to,
          to_options: a.action_data.to_options || [],
          cc_options: a.action_data.cc_options || [],
          bcc_options: a.action_data.bcc_options || [],
        },
      }
    case 'start_phase':
      return {
        id: a.id,
        action_type: a.action_type,
        action_data: {
          pipeline_template: a.action_data.pipeline_template,
          pipeline_template_id: a.action_data.pipeline_template?.id || null,
          phase_template: a.action_data.phase_template,
          phase_template_id: a.action_data.phase_template?.id || null,
        },
      }
    case 'create_activity':
      return {
        id: a.id,
        action_type: a.action_type,
        action_data: {
          pipeline_template: a.action_data.pipeline_template,
          pipeline_template_id: a.action_data.pipeline_template?.id || null,
          phase_template: a.action_data.phase_template,
          phase_template_id: a.action_data.phase_template?.id || null,
          title: a.action_data.title,
          activity_type_id: a.action_data.activity_type?.id || null,
          priority: a.action_data.priority,
          priority_id: a.action_data.priority?.id || null,
          commit_type: a.action_data.commit_type,
          deadline_days: a.action_data.deadline_days,
          deadline_hours: a.action_data.deadline_hours,
          deadline_minutes: a.action_data.deadline_minutes,
          notes: a.action_data.notes,
          assignee_first_request_assigned:
            a.action_data.assignee_first_request_assigned,
          responsible_user: a.action_data.responsible_user,
          responsible_user_id: a.action_data.responsible_user?.id || null,
          visible_to_tenant: a.action_data.visible_to_tenant || false,
        },
      }
    case 'create_request':
      return {
        ...a,
        action_data: {
          ...a.action_data,
          category_id: a.action_data.category?.id || null,
          label_ids: a.action_data.labels?.map(l => l.id) || [],
          flat_id: a.action_data.flat?.id || null,
          building_id: a.action_data.building?.id || null,
          owner_id: a.action_data.owner?.id || null,
          contractor_ids: a.action_data.contractors?.map(c => c.id) || [],
          assignee_ids: a.action_data.assignees?.map(a => a.id) || [],
          files_options: a.action_data.files_options || [],
          file_ids: a.action_data.files?.map(f => f.id) || [],
          related_request_ids:
            a.action_data.related_requests?.map(r => r.id) || [],
        },
      }
    case 'create_post':
      return {
        ...a,
        action_data: {
          ...a.action_data,
          building_ids: a.action_data.buildings?.map(b => b.id) || [],
          building_group_ids:
            a.action_data.building_groups?.map(g => g.id) || [],
          file_ids: a.action_data.files?.map(f => f.id),
        },
      }
    case 'search_request_duplicates':
      return {
        ...a,
        action_data: {
          ...a.action_data,
        },
      }
    default:
      return a
  }
}

export const validateAction = action => {
  switch (action.action_type) {
    case 'send_email': {
      const {
        to,
        to_options,
        text,
        subject,
        reply_to_mail_account_id,
        send_by_mail_account_id,
        send_by_system_credentials,
      } = action.action_data

      if (
        (!to?.length && !to_options?.length) ||
        !text?.length ||
        !subject?.length
      ) {
        return false
      }

      if (!send_by_mail_account_id && !send_by_system_credentials) {
        return false
      }

      if (send_by_system_credentials && !reply_to_mail_account_id) {
        return false
      }

      return true
    }
    case 'start_phase': {
      const { pipeline_template_id, phase_template_id } = action.action_data

      return pipeline_template_id && phase_template_id
    }
    case 'create_activity': {
      const {
        pipeline_template_id,
        phase_template_id,
        activity_type_id,
        title,
        commit_type,
        assignee_first_request_assigned,
        responsible_user_id,
      } = action.action_data

      if (
        !pipeline_template_id ||
        !phase_template_id ||
        !activity_type_id ||
        !title?.length
      ) {
        return false
      }

      if (![YESNO_COMMIT_TYPE, DONE_COMMIT_TYPE].includes(commit_type)) {
        return false
      }

      if (!assignee_first_request_assigned && !responsible_user_id) {
        return false
      }

      return true
    }
    case 'create_request': {
      const { text, title, deadline_hours, deadline_minutes, deadline_days } =
        action.action_data

      if (!text?.length || !title?.length) {
        return false
      }

      if (
        deadline_days === undefined ||
        deadline_hours === undefined ||
        deadline_minutes === undefined
      ) {
        return false
      }

      return true
    }
    case 'create_post': {
      const {
        text,
        title,
        audiences,
        online_period_hours,
        online_period_minutes,
        online_period_days,
      } = action.action_data

      if (
        online_period_hours === undefined ||
        online_period_minutes === undefined ||
        online_period_days === undefined
      ) {
        return false
      }

      return text?.length && title?.length && audiences?.length
    }
    case 'search_request_duplicates':
      // eslint-disable-next-line no-case-declarations
      const {
        created_period_days,
        created_period_hours,
        created_period_minutes,
        additional_condition,
      } = action.action_data

      if (
        created_period_hours === undefined ||
        created_period_minutes === undefined ||
        created_period_days === undefined
      ) {
        return false
      }

      if (!validateCondition(additional_condition, true)) {
        return false
      }

      return true

    default:
      return true
  }
}

const validCondition = c => {
  if (c.field === 'activity_deadline' && c.operator) {
    return true
  } else {
    for (let key in c) {
      if (!c[key] && c[key] !== 0) {
        return false
      }
    }
  }

  return true
}

export const validateCondition = (condition, isAdditional = false) => {
  if (!isAdditional && isEqual(condition, EMPTY_CONDITION)) {
    return true
  }

  if (isEmpty(condition)) {
    return true
  }

  if (Object.keys(condition).length > 1) {
    if (!validCondition(condition)) {
      return false
    }
  } else {
    const conditionType = Object.keys(condition)[0]
    for (let i = 0; i < condition[conditionType].length; i++) {
      if (!validCondition(condition[conditionType][i])) {
        return false
      }
    }
  }

  return true
}
