// @flow

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import { useOverflow } from '../../hooks'

import Modal from '../Modal'
import ModalBody from '../Modal/ModalBody'
import ModalHead from '../Modal/ModalHead'
import ModalButtons from '../Modal/ModalButtons'
import ModalCloseButton from '../Modal/ModalCloseButton'
import ModalRow from '../Modal/ModalRow'
import ModalCell from '../Modal/ModalCell'
import ModalLabel from '../Modal/ModalLabel'

import InputField from '../InputField'
import NewSelectSimple from '../NewSelectSimple'
import Button from '../Button'

import { getTriggerEvents } from '../../core/api/api.automate'

const MAX_NAME_LENGTH = 128

type Props = {
  addAutomate?: Function,
  isEdit?: boolean,
  name?: string,
  onClose: Function,
  updateName?: Function,
}

const AutomateModal = (props: Props): Node => {
  const { isEdit } = props
  const { t } = useTranslation('Automate')
  const [name, setName] = useState(props.name || '')
  const [triggerOptions, setTriggerOptions] = useState([])
  const [trigger, setTrigger] = useState(null)

  useOverflow()

  useEffect(() => {
    getTriggerEvents().then(data => {
      setTriggerOptions(
        data.events.map(({ code }) => ({
          label: t(`Trigger.${code}`),
          value: code,
        }))
      )
    })
  }, [])

  return (
    <Modal isOpen>
      <ModalCloseButton onClose={props.onClose} />
      <ModalHead title={t(isEdit ? 'NameEditTitle' : 'NameAddTitle')} />
      <ModalBody>
        <ModalRow>
          <ModalCell>
            <ModalLabel>{t('Name')}</ModalLabel>
          </ModalCell>
          <ModalCell gridNumber={2}>
            <InputField
              autoFocus
              name='name'
              value={name}
              maxLength={MAX_NAME_LENGTH}
              placeholder={t('NamePlaceholder')}
              onChange={e => setName(e.target.value)}
            />
          </ModalCell>
        </ModalRow>
        {!isEdit && (
          <ModalRow>
            <ModalCell>
              <ModalLabel>{t('Trigger')}</ModalLabel>
            </ModalCell>
            <ModalCell gridNumber={2}>
              <NewSelectSimple
                name='trigger'
                options={triggerOptions}
                placeholder={t('SelectTrigger')}
                value={trigger}
                onChange={setTrigger}
              />
            </ModalCell>
          </ModalRow>
        )}
      </ModalBody>
      <ModalButtons>
        <Button.Save
          disabled={!name.length || (!isEdit && !trigger)}
          onClick={() =>
            isEdit
              ? props.updateName(name)
              : props.addAutomate(name, trigger?.value)
          }
        >
          {t('Common:Save')}
        </Button.Save>
        <Button.Cancel onClick={props.onClose}>
          {t('Common:Cancel')}
        </Button.Cancel>
      </ModalButtons>
    </Modal>
  )
}

export default AutomateModal
