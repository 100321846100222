// @flow

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import type { Node } from 'react'

import { getAutomationHistory } from '../../core/api/api.automate'

import { useLoad, usePathname } from '../../hooks'
import { getFormattedDate } from '../../utils/utils'

import Content from '../Content'
import Loader from '../Loader'

import NewTable from '../NewTable'
import NewTableHeader from '../NewTable/NewTableHeader'
import NewTableHeaderRow from '../NewTable/NewTableHeaderRow'
import NewTableHeaderCell from '../NewTable/NewTableHeaderCell'
import NewTableBody from '../NewTable/NewTableBody'
import NewTableBodyRow from '../NewTable/NewTableBodyRow'
import NewTableBodyCell from '../NewTable/NewTableBodyCell'
import TableCellUsers from '../TableCellUsers'

import EmptyList from '../EmptyList'
import Toolbox from '../Toolbox'
import ToolboxCell from '../Users/UserList/Toolbox/ToolboxCell'
import TopPagination from '../Pagination/TopPagination'
import BottomPagination from '../Pagination/BottomPagination'

import addRedirectToFirstPage from '../../hocs/addRedirectToFirstPage'

import DangerIcon from '../../../static/icons/danger.svg'
import Tooltip from '../Tooltip/Tooltip'

import {
  STATUS_HISTORY_NAME,
  STATUS_HISTORY_COLOR,
} from './AutomateTable/Automate.constants'

import styles from './AutomateTable/AutomateTable.module.scss'

type Props = {
  id?: number,
  setCount: number => void,
}

const AutomateHistory = (props: Props): Node => {
  const { id: automation_id, setCount } = props
  const { t } = useTranslation('Automate')
  const isLocked = usePathname()
  const [data, isLoading] = useLoad(
    getAutomationHistory,
    { automation_id },
    isLocked
  )

  useEffect(() => {
    setCount(data?.meta?.count)
  }, [data])

  if (isLoading) {
    return <Loader type='big' text={false} />
  }

  return (
    <Content>
      {data?.results?.objects?.length > 0 && (
        <Toolbox>
          <ToolboxCell isRight>
            <TopPagination
              classes='pager pager--large pager--default-2'
              meta={data.meta}
            />
          </ToolboxCell>
        </Toolbox>
      )}
      {data?.results?.objects?.length > 0 && (
        <NewTable loading={isLoading}>
          <NewTableHeader>
            <NewTableHeaderRow>
              <NewTableHeaderCell title={t('Name')} sortKey='name' />
              <NewTableHeaderCell
                title={t('Trigger')}
                sortKey='trigger_event'
              />
              <NewTableHeaderCell title={t('StatusHistory')} sortKey='status' />
              <NewTableHeaderCell
                title={t('Initiator')}
                sortKey='initiated_by_fullname'
              />
              <NewTableHeaderCell title={t('StartDate')} sortKey='created' />
            </NewTableHeaderRow>
          </NewTableHeader>
          <NewTableBody>
            {data?.results?.objects?.length > 0
              ? data.results.objects.map(item => (
                  <React.Fragment key={item.id}>
                    <NewTableBodyRow>
                      <NewTableBodyCell title={item.name} />
                      <NewTableBodyCell
                        title={t(`Trigger.${item.automation.trigger_event}`)}
                      />
                      <NewTableBodyCell>
                        <span
                          className={styles.statusCell}
                          style={
                            STATUS_HISTORY_COLOR[item.status]
                              ? { color: STATUS_HISTORY_COLOR[item.status] }
                              : {}
                          }
                        >
                          {STATUS_HISTORY_NAME[item.status]
                            ? t(`Log.${STATUS_HISTORY_NAME[item.status]}`)
                            : '-'}
                        </span>
                        {item.summary_text && (
                          <Tooltip text={item.summary_text}>
                            <div className={styles.logSummary}>
                              <DangerIcon
                                style={
                                  STATUS_HISTORY_COLOR[item.status]
                                    ? {
                                        fill: STATUS_HISTORY_COLOR[item.status],
                                      }
                                    : {}
                                }
                              />
                              <span>{t('Details')}</span>
                            </div>
                          </Tooltip>
                        )}
                      </NewTableBodyCell>
                      <NewTableBodyCell className={styles.users}>
                        <TableCellUsers items={[item.initiated_by]} />
                      </NewTableBodyCell>
                      <NewTableBodyCell
                        title={getFormattedDate(item.created, {
                          specifyHours: true,
                        })}
                      />
                    </NewTableBodyRow>
                  </React.Fragment>
                ))
              : null}
          </NewTableBody>
        </NewTable>
      )}
      {!data?.results?.objects?.length && (
        <EmptyList embedded icon='automate' title={t('EmptyListHistory')} />
      )}
      {data?.results?.objects?.length > 0 && (
        <BottomPagination meta={data.meta} classes='paginator-2' />
      )}
    </Content>
  )
}

export default addRedirectToFirstPage(AutomateHistory)
