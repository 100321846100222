// @flow

import { all, put, call, fork, takeLatest } from 'redux-saga/effects'
import { mapValues, snakeCase } from 'lodash-es'
import { get } from 'lodash-es'
import type { Saga } from 'redux-saga'

import { WIDGET_CHAT_PARTICIPANTS_LIST_INITIATED } from '../../components/widgets/WidgetParticipantsList/WidgetParticipantsList.actionTypes'
import api from '../../core/api'
import {
  serverError,
  globalModalError,
} from '../../components/Layout/Layout.actions'
import * as actionTypes from './Chatroom.actionTypes'
import * as Actions from './Chatroom.actions'
import {
  WIDGET_FILES_INIT,
  widgetFilesWereDeleted,
} from '../../components/widgets/WidgetFiles/WidgetFiles.actionTypes'
import { redirectTo404 } from '../../utils/routing'

function* watchInitiate() {
  yield takeLatest(actionTypes.CHATROOM_INITIATE, initiate)
}

function* watchGetNewMessagesInit() {
  yield takeLatest(actionTypes.GET_NEW_MESSAGES_INITIATING, getNewMessages)
}

function* watchGetOldMessagesInit() {
  yield takeLatest(actionTypes.GET_OLD_MESSAGES_INITIATING, getOldMessages)
}

function* watchAddMembers() {
  yield takeLatest(actionTypes.ADD_MEMBERS_INITIATING, addMembers)
}

function* watchRemoveMembers() {
  yield takeLatest(actionTypes.REMOVE_MEMBERS_INITIATING, removeMembers)
}

function* watchCreate() {
  yield takeLatest(actionTypes.CHATROOM_CREATE, create)
}

function* watchSendMessage() {
  yield takeLatest(actionTypes.SEND_MESSAGE_INITIATING, sendMessage)
}

function* watchRenameChatroom() {
  yield takeLatest(actionTypes.RENAME_CHATROOM_INITIATING, renameChatroom)
}

function* watchAddLike() {
  yield takeLatest(actionTypes.LIKE_MESSAGE_INITIATING, addLike)
}

function* watchUpdateLabels() {
  yield takeLatest(actionTypes.UPDATE_LABELS_INIT, updateRequestLabels)
}

function* watchDeleteMessage() {
  yield takeLatest(actionTypes.CHATROOM_DELETE_MESSAGE, deleteMessage)
}

function* watchDeleteFiles() {
  yield takeLatest(actionTypes.CHATROOM_DELETE_FILES, deleteFiles)
}

function* watchUpdateMessages() {
  yield takeLatest(actionTypes.CHATROOM_MESSAGES_UPDATING, updateMessages)
}

function* deleteMessage({ deletingMessageUuid, deletingFileIds, outbound }) {
  try {
    yield call(api.messages.deleteMessage, deletingMessageUuid, outbound)
    yield put({ type: actionTypes.DELETE_MESSAGE, deletingMessageUuid })
    yield put(widgetFilesWereDeleted(deletingFileIds))
  } catch (error) {
    yield put(serverError(error))
    yield put(Actions.getOldMessagesError(error))
  }
}

function* deleteFiles({
  editingMessageUuid,
  fileIds,
  deletingFileId,
  outbound,
}) {
  try {
    const patchParams = {
      files: JSON.stringify(fileIds),
    }

    const message = yield call(
      api.messages.updateMessage,
      editingMessageUuid,
      patchParams,
      outbound
    )

    yield put(Actions.updateMessage(message))
    yield put(widgetFilesWereDeleted([deletingFileId]))
  } catch (error) {
    yield put(serverError(error))
    yield put(Actions.getOldMessagesError(error))
  }
}

function* initiate({ uuid, limit, request, outbound }) {
  try {
    if (uuid === 'create') {
      return
    }

    if (!request) {
      yield put({ type: WIDGET_FILES_INIT, chatroom: uuid })
    }

    const [messages, members, info] = yield all([
      call(
        api.messages.getMessages,
        uuid,
        limit,
        undefined,
        true,
        undefined,
        outbound
      ),
      call(api.messages.getMembers, uuid, outbound),
      call(api.messages.getChatroomInfo, uuid, outbound),
    ])

    yield put(Actions.wasInitiated({ uuid, messages, members, info }))
    yield call(api.messages.readMessages, uuid, outbound)

    yield put(Actions.updateCounters())
  } catch (error) {
    redirectTo404(error) || (yield put(serverError(error)))
    yield put(Actions.getOldMessagesError(error))
  }
}

function* getOldMessages({ uuid, limit, messageUuid, page, outbound }) {
  try {
    const messages = yield call(
      api.messages.getMessages,
      uuid,
      limit,
      messageUuid,
      true,
      page,
      outbound
    )
    yield put(Actions.getOldMessagesInitiated(messages))
    yield call(api.messages.readMessages, uuid)
  } catch (error) {
    yield put(serverError(error))
    yield put(Actions.getOldMessagesError(error))
  }
}

function* getNewMessages({ uuid, limit, messageUuid, outbound }) {
  try {
    const messages = yield call(
      api.messages.getMessages,
      uuid,
      limit,
      messageUuid,
      false,
      undefined,
      outbound
    )

    yield put(Actions.getNewMessagesInitiated(uuid, messages.results.objects))
  } catch (error) {
    yield put(serverError(error))
    yield put(Actions.getNewMessagesError(error))
  }
}

function* addMembers({ uuid, users }) {
  try {
    const params = { users: JSON.stringify(users) }
    const members = yield call(api.messages.addMembers, uuid, params)
    yield put(Actions.addMembersInitiated(members))
    yield put({ type: WIDGET_CHAT_PARTICIPANTS_LIST_INITIATED, data: members })
  } catch (error) {
    yield put(serverError(error))
    yield put(Actions.addMembersError(error))
  }
}

function* removeMembers({ uuid, users }) {
  try {
    const members = yield call(
      api.messages.removeMembers,
      uuid,
      users.join(',')
    )
    yield put(Actions.removeMembersInitiated(users))
    yield put({ type: WIDGET_CHAT_PARTICIPANTS_LIST_INITIATED, data: members })
  } catch (error) {
    yield put(serverError(error))
    yield put(Actions.removeMembersError(error))
  }
}

function* create(action) {
  try {
    const {
      users,
      text,
      files,
      title,
      request,
      isPublic,
      post,
      requestId,
      building,
      feedback,
      flat,
      outbound,
    } = action.params

    const params = {}

    if (users && users.length > 0) {
      params.users = JSON.stringify(users)
    }

    if (text && text.length > 0) {
      params.text = text
    }

    if (files) {
      params.files = JSON.stringify(files)
    }

    const paramsObject = {
      title,
      request,
      post,
      building,
      flat,
      isPublic,
      feedback,
      outbound,
    }
    mapValues(paramsObject, (value, key) => {
      if (value || typeof value === 'boolean') {
        params[snakeCase(key)] = value
      }
    })

    const result = yield call(api.messages.createChatroom, params)
    yield put(Actions.wasCreated(result.uuid, request, isPublic))

    if (requestId) {
      yield put(Actions.initiateWidgetFiles({ requestId }))
    }
  } catch (error) {
    yield put(serverError(error))
    // TODO handle create error
  }
}

function* sendMessage({
  uuid,
  text,
  fileIds,
  requestId,
  newMemberToSet,
  outbound,
}) {
  try {
    const params = { chatroom: uuid, outbound }

    if (text.length > 0) {
      params.text = text
    }

    if (fileIds) {
      params.files = JSON.stringify(fileIds)
    }

    const sentMessage = yield call(api.messages.sendMessage, params)
    yield put(Actions.sendMessageInitiated(uuid, sentMessage, newMemberToSet))

    if (fileIds && fileIds.length > 0) {
      if (requestId) {
        yield put(Actions.initiateWidgetFiles({ requestId }))
      } else {
        yield put(Actions.initiateWidgetFiles({ chatroomUuid: uuid }))
      }
    }
  } catch (error) {
    const status = get(error, ['message', 'response', 'status'], null)

    if (status === 403) {
      const errorText = get(
        error,
        ['message', 'response', 'data', 'errors'],
        null
      )
      yield put(globalModalError(errorText, '', true))
    } else {
      yield put(serverError(error))
      yield put(Actions.sendMessageError(error))
    }
  }
}

function* renameChatroom({ chatroomUuid, title }) {
  try {
    const params = { title }
    yield call(api.messages.updateChatroom, chatroomUuid, params)
    yield put(Actions.renameChatroomInitiated(title))
  } catch (error) {
    yield put(serverError(error))
    yield put(Actions.renameChatroomError(error))
  }
}

function* addLike({ messageUuid, isLike, isRemove, outbound }) {
  try {
    switch (true) {
      case isLike && isRemove: // remove like
        yield call(api.messages.removeDislikeFromMessage, messageUuid, outbound)
        break
      case isLike && !isRemove: // add like
        yield call(api.messages.addLikeToMessage, messageUuid, outbound)
        break
      case !isLike && isRemove: // remove dislike
        yield call(api.messages.removeDislikeFromMessage, messageUuid, outbound)
        break
      case !isLike && !isRemove: // add dislike
        yield call(api.messages.addDislikeToMessage, messageUuid, outbound)
        break
      default:
    }
    yield put(Actions.likeMessageInitiated(messageUuid, isLike, isRemove))
  } catch (error) {
    yield put(serverError(error))
    yield put(Actions.likeMessageError(error))
  }
}

function* updateRequestLabels({ labels, chatroomUuid }) {
  try {
    const labelIds = labels.filter(l => l.checked).map(l => l.id)
    const { label_objs: labelObjs } = yield call(
      api.messages.updateChatroom,
      chatroomUuid,
      { labels: JSON.stringify(labelIds) }
    )
    yield put(Actions.updateLabelsSuccess(chatroomUuid, labelObjs))
  } catch (error) {
    yield put(serverError(error))
    yield put(Actions.updateLabelsError(error))
  }
}

function* updateMessages({ uuid, limit, messageUuid }) {
  try {
    const archivedInfo = yield call(
      api.messages.getArchivedInfo,
      uuid,
      messageUuid,
      limit
    )

    yield put({
      type: actionTypes.CHATROOM_MESSAGES_UPDATED,
      uuid,
      archivedInfo,
    })
  } catch (error) {
    yield put(serverError(error))
    yield put(Actions.updateLabelsError(error))
  }
}

export default function* watch(): Saga<void> {
  yield all([
    fork(watchInitiate),
    fork(watchGetNewMessagesInit),
    fork(watchGetOldMessagesInit),
    fork(watchAddMembers),
    fork(watchRemoveMembers),
    fork(watchCreate),
    fork(watchSendMessage),
    fork(watchRenameChatroom),
    fork(watchAddLike),
    fork(watchUpdateLabels),
    fork(watchDeleteMessage),
    fork(watchDeleteFiles),
    fork(watchUpdateMessages),
  ])
}
