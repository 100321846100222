// @flow

import React, { useEffect, useState } from 'react'
import type { Node } from 'react'
import classnames from 'classnames'
import styles from './ScrollButtonEmails.module.scss'
import useScrollPosition from '../../../hooks/useScrollPosition'

import Arrow from '../../Button/Arrow'

type Props = {
  emailsListRef: Node,
  isCanShow: boolean,
}

const ScrollButtonEmails = ({ isCanShow, emailsListRef }: Props): Node => {
  const UP = 'UP'
  const DOWN = 'DOWN'
  const scroll = useScrollPosition()
  const [isShow, setIsShow] = useState(false)
  const [direction, setDirection] = useState(DOWN)

  useEffect(() => {
    const { offsetTop } = getPosition()
    const show = isCanShow && scroll >= offsetTop

    if (show) {
      setIsShow(true)
    } else {
      setIsShow(false)
    }
  }, [scroll])

  useEffect(() => {
    const { offsetTop, height } = getPosition()
    const currentDirection = scroll >= offsetTop + height / 6

    if (currentDirection) {
      setDirection(UP)
    } else {
      setDirection(DOWN)
    }
  }, [scroll])

  const getPosition = () => {
    const emailCurrentList = emailsListRef.current
    const { offsetTop } = emailCurrentList
    const { top, height } = emailCurrentList.getBoundingClientRect()

    return { top, height, offsetTop }
  }

  const scrollTo = () => {
    const { offsetTop, height } = getPosition()
    const scrollSettings = {
      top: direction === UP ? offsetTop : offsetTop + height,
      left: 0,
      behavior: 'smooth',
    }

    window.scrollTo(scrollSettings)
  }

  const scrollButton = classnames(styles.scrollButtonEmails, {
    [styles.show]: isShow,
    [styles.up]: direction === UP,
  })

  return (
    <div className={scrollButton}>
      <Arrow onClick={scrollTo} />
    </div>
  )
}

export default ScrollButtonEmails
