// @flow

import React from 'react'
import type { Node } from 'react'
import type {
  RouterHistory,
  Location,
  Match,
  StaticRouterContext,
} from 'react-router-dom'
import { useSelector } from 'react-redux'

import IndexContent from './IndexContent'

import {
  getRedesignUiSectionsConfig,
  getUkConfig,
} from '../utils/commonSelectors'

type Props = {
  history: RouterHistory,
  location: Location,
  match: Match,
  staticContext: StaticRouterContext,
}

const IndexPage = (props: Props): Node => {
  const {
    location,
    match: {
      params: { search },
    },
    staticContext,
    history,
  } = props

  const ukConfig = useSelector(getUkConfig)
  const redesignSections = useSelector(getRedesignUiSectionsConfig)
  const currentHostname = window.location.hostname

  if (
    currentHostname !== 'localhost' &&
    location.pathname === '/' &&
    ukConfig?.is_new_design_available &&
    redesignSections?.newsboard
  ) {
    window.location.replace('/redesign/newsboard')
  }

  return (
    <div className='content__col'>
      <IndexContent
        staticContext={staticContext}
        search={search}
        history={history}
        location={location}
      />
    </div>
  )
}

export default IndexPage
