// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { Node } from 'react'

import Icon from '../Icon'
import Modal from '../Modal'
import ConfirmationPopup from '../modals/ConfirmationPopup'
import ErrorPopup from '../modals/ErrorPopup'

import { downloadArchive } from '../../utils/file'

import styles from './MassDownload.module.scss'

type Props = {
  fileIds: Array<number>,
  outbound?: string,
}

const MassDownload = (props: Props): Node => {
  const { fileIds, outbound } = props

  const { t } = useTranslation('Files')

  const [downloading, setDownloading] = useState(false)
  const [modal, setModal] = useState(null)

  const handleDownload = () => {
    if (!downloading) {
      setModal(
        <ConfirmationPopup
          confirm={t('Yes')}
          title={t('MassDownloadTitle')}
          text={t('MassDownloadText')}
          onClose={hideModal}
          onOk={() => {
            confirmDownload()
          }}
        />
      )
    }
  }

  const confirmDownload = () => {
    hideModal()
    setDownloading(true)
    downloadArchive(fileIds, outbound).then(res => {
      if (res.message?.response?.data?.errors) {
        setModal(
          <ErrorPopup
            isServerError
            needTranslate
            text={res.message?.response?.data?.errors}
            title={t('FilesDownloadError')}
            confirm={t('Continue')}
            onOk={hideModal}
            onClose={hideModal}
          />
        )
      } else {
        window.open(res)
      }

      setDownloading(false)
    })
  }

  const hideModal = () => setModal(null)

  return (
    <>
      <div className={styles.download} onClick={handleDownload}>
        <Icon id={downloading ? 'loader' : 'download'} />
        <span>{t('Mail:DownloadArchive')}</span>
      </div>
      <Modal isOpen={!!modal} onRequestClose={hideModal}>
        {modal}
      </Modal>
    </>
  )
}

export default MassDownload
