// @flow

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { isEqual, toString } from 'lodash-es'
import classnames from 'classnames'

import Button from '../../Button'
import InputNumber from '../../InputNumber'
import * as actions from './FlatDescription.actionTypes'

const MAX_ROOM = 20
const MAX_AREA = 99999

const propTypes = {
  flat: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  working: PropTypes.bool,
  dispatch: PropTypes.func,
  data: PropTypes.object,
  edit: PropTypes.bool,
  saving: PropTypes.bool,
  t: PropTypes.func,
  noMargin: PropTypes.bool,
}

class FlatDescription extends Component {
  componentDidMount() {
    this.props.dispatch({
      type: actions.FLAT_DESCRIPTION_INIT,
      data: this.getInitial(),
    })
  }

  componentDidUpdate(prevProps) {
    const { saving, working } = prevProps

    if (saving && working && !this.props.working) {
      this.props.dispatch({ type: actions.FLAT_DESCRIPTION_SAVED })
    }
  }

  getInitial = () => {
    const { area, live_area, room_count } = this.props.flat

    return {
      area,
      live_area,
      room_count,
    }
  }

  canSave = () => {
    const { data } = this.props
    const flat = this.getInitial()

    return !isEqual(data, flat)
  }

  edit = () => {
    this.props.dispatch({ type: actions.FLAT_DESCRIPTION_EDIT_ON })
  }

  cancel = () => {
    this.props.dispatch({
      type: actions.FLAT_DESCRIPTION_EDIT_OFF,
      data: this.getInitial(),
    })
  }

  save = () => {
    const { onSave, data } = this.props
    const filteredData = Object.keys(data).reduce((acc, key) => {
      if (!data[key]) {
        acc[key] = ''
      } else {
        acc[key] = data[key]
      }

      return acc
    }, {})

    // TODO костыль, нужно будет переделать
    this.props.dispatch({
      type: actions.FLAT_DESCRIPTION_SET_VALUE,
      name: 'live_area',
      value: document
        .querySelector('.apartment-description-live_area-value')
        .getElementsByTagName('input')[0]
        .value.replace(',', '.'),
    })

    onSave(filteredData)
    this.props.dispatch({ type: actions.FLAT_DESCRIPTION_SAVE })
  }

  updateField = (name, value, float) => {
    this.props.dispatch({
      type: actions.FLAT_DESCRIPTION_SET_VALUE,
      name,
      value: float ? value.replace(',', '.') : value,
    })
  }

  renderRow = (name, title, max, float = true, autofocus = false) => {
    const { edit, data, t } = this.props
    const value = data[name] >= max ? max : data[name] || ''
    const cellEditClass = classnames(
      'apartment-create__cell apartment-create__cell--b',
      `apartment-description-${name}-value`
    )
    const cellClass = classnames(
      'apartment-profile__cell apartment-profile__cell--c apartment-create__cell-font',
      `apartment-description-${name}`
    )

    const inputValue =
      float && value ? toString(value).replace('.', ',') : value

    return (
      <div className='apartment-create__row apartment-create__row--a'>
        <div className='apartment-create__cell apartment-create__cell--a apartment-create__cell-font'>
          {t(title)}
        </div>
        {edit ? (
          <div className={cellEditClass}>
            <InputNumber
              className='input input--default input--large input--block'
              type='text'
              name={name}
              autofocus={autofocus}
              value={inputValue}
              max={max}
              float={float}
              onChange={this.updateField}
            />
          </div>
        ) : (
          <div className={cellClass}>{inputValue || t('EmptyField')}</div>
        )}
      </div>
    )
  }

  render() {
    const {
      data: { area },
      flat: { permissions, soft_archived: softArchived },
      edit,
      saving,
      t,
      noMargin,
    } = this.props
    const maxLiveArea = area ? parseFloat(area) : MAX_AREA
    let sectionClass = classnames(
      'apartment-create unit unit--default flat-description',
      {
        'working-overlay': saving,
      }
    )
    sectionClass = `${sectionClass}${noMargin ? ' unit--nomargin' : ''}`

    return (
      <section className={sectionClass}>
        <h2 className='unit__title apartment-create__title'>
          {t('Description')}
          {!softArchived && !edit && permissions.can_edit && (
            <Button.Edit onClick={this.edit} />
          )}
        </h2>
        {this.renderRow('room_count', 'RoomCount', MAX_ROOM, false, true)}
        {this.renderRow('area', 'Area', MAX_AREA)}
        {this.renderRow('live_area', 'LivingArea', maxLiveArea)}
        {edit && (
          <div className='apartment-create__submit'>
            <button
              type='button'
              className='button button--large button--default button--success-2'
              disabled={!this.canSave()}
              onClick={this.save}
            >
              {saving && <span className='button__spinner' />}
              {t('Save')}
            </button>
            <button
              type='button'
              className='button button--large button--danger-3'
              onClick={this.cancel}
            >
              {t('Cancel')}
            </button>
          </div>
        )}
      </section>
    )
  }
}

FlatDescription.propTypes = propTypes

export default withTranslation('Flat')(FlatDescription)
