// @flow

export const STATUS_ACTIVE = 10
export const STATUS_INACTIVE = 0

export const TRIGGER_ACTIVITY_UPDATED = 'activity.updated'
export const TRIGGER_REQUEST_CREATED = 'request.created'

export const CONDITION_TYPE_OR = 'or'
export const CONDITION_TYPE_AND = 'and'

export const EMPTY_CONDITION = {
  field: null,
  operator: null,
  value: '',
}

export const ADDITIONAL_CONDITION_FIELDS = [
  'request_title',
  'request_description',
]

export const STATUS_COLORS = {
  [STATUS_ACTIVE]: '#9ec221',
  [STATUS_INACTIVE]: '#e44440',
}

export const STATUS_HISTORY_COLOR = [
  null,
  '#d3b801',
  '#9ec221',
  '#e44440',
  '#b0b0b0',
]
export const STATUS_HISTORY_NAME = [
  null,
  'in_progress',
  'success',
  'failed',
  'canceled',
]

export const OMIT_FIELDS = [
  'assignees',
  'building',
  'buildings',
  'building_groups',
  'category',
  'contractors',
  'files',
  'flat',
  'labels',
  'owner',
  'phase_template',
  'pipeline_template',
  'priority',
  'related_requests',
  'responsible_user',
]

export const WEEKDAYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]
