// @flow

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Row } from '../../Block'
import Button from '../../Button'
import SelectUser from '../../Select/SelectUser'
import SelectValue from '../../Select/ValueItem'
import {
  USER_GROUPS,
  USER_STATUS,
  ACTIVE_STATUS,
  PREVIEW_MANAGERS_COUNT,
} from '../../../constants'
import { getUrlForAvatar, getUserId } from '../../../utils/utils'
import { getUser } from '../../../utils/commonSelectors'

import { globalModalError } from '../../../components/Layout/Layout.actions'

import styles from './ManagersBar.module.scss'

const Managers = props => {
  const { t } = useTranslation('Building')
  const {
    data: { owner_objs: owners, permissions = {} },
    working,
    editingManagers,
    title,
    margin,
    max,
    maxPreview = PREVIEW_MANAGERS_COUNT,
  } = props
  const {
    can_edit_manager: canEdit,
    can_manage_responsible_users: canEditManagers,
  } = permissions
  const selectParams = {
    all: true,
    page_size: max || undefined,
  }

  const onEdit = canEdit || canEditManagers ? props.editManagers : null
  const user = useSelector(state => getUser(state))
  const [showAll, setShowAll] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (working) {
      setShowAll(false)
    }
  }, [working])

  const getName = man =>
    man.name ? `${man.name} ${man.second_name || ''}` : man.fullname
  const renderManager = man => (
    <div className={styles.manager} key={`worker-${getUserId(man) || ''}`}>
      <SelectValue
        inline
        profile
        round
        isLink={
          !(
            user.group === USER_GROUPS.dweller &&
            USER_STATUS[man.status] !== ACTIVE_STATUS
          )
        }
        avatar={getUrlForAvatar(man)}
        profileId={man.id}
        group={man.group}
        text={getName(man)}
      />
    </div>
  )

  const onLimitError = () => {
    const {
      limitError: { text, title },
    } = props
    dispatch(globalModalError(text, title, true))
  }

  if (editingManagers) {
    const initialIds = owners.map(getUserId)

    return (
      <Row title={title || t('Manager')}>
        <SelectUser
          cleanable
          multi
          explicitLoading
          isManager
          order
          round
          margin={margin}
          id='building-manager'
          maxLength={max}
          initialValueId={initialIds}
          params={selectParams}
          onChange={props.updateManagers}
          onLimitError={onLimitError}
        />
        <div
          style={{
            marginTop: '15px',
            marginBottom: '5px',
          }}
        >
          {props.saveManagers && (
            <Button.Save working={working} onClick={props.saveManagers}>
              {t('Save')}
            </Button.Save>
          )}
          {props.cancelManagers && (
            <Button.Cancel onClick={props.cancelManagers}>
              {t('Cancel')}
            </Button.Cancel>
          )}
        </div>
      </Row>
    )
  }

  return (
    <Row title={title || t('Manager')} onEdit={onEdit}>
      {owners.length ? (
        <div className={styles.managers}>
          {owners
            .slice(0, (!showAll && maxPreview) || owners.length)
            .map(renderManager)}
          {!showAll && owners.length > maxPreview && (
            <div className={styles.counter} onClick={() => setShowAll(true)}>
              +{owners.length - maxPreview}
            </div>
          )}
        </div>
      ) : (
        <span className='bar__submit-label_light bar__submit-label'>
          {t('SmthEmpty')}
        </span>
      )}
    </Row>
  )
}

export default Managers
