// @flow

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Radio from '../Radio'
import Button from '../Button'
import ConditionField from './ConditionField'
import Warning from '../Warning'

import {
  EMPTY_CONDITION,
  CONDITION_TYPE_AND,
  CONDITION_TYPE_OR,
} from './AutomateTable/Automate.constants'

import styles from './AutomateInfo.module.scss'

import { isEmpty, isEqual, cloneDeep, uniqueId } from 'lodash-es'

type Props = {
  additional?: boolean,
  canEdit: boolean,
  condition: Object,
  copyCondition?: Object,
  setCondition: Object => void,
  trigger: string,
}

const Conditions = (props: Props): Node => {
  const {
    additional,
    canEdit,
    condition,
    copyCondition,
    setCondition,
    trigger,
  } = props

  const { t } = useTranslation('Automate')

  const oneCondition = () => Object.keys(condition).length > 1

  const [conditionType, setConditionType] = useState(
    oneCondition() || isEmpty(condition)
      ? CONDITION_TYPE_AND
      : Object.keys(condition)[0]
  )

  useEffect(() => {
    setConditionType(
      oneCondition() || isEmpty(condition)
        ? CONDITION_TYPE_AND
        : Object.keys(condition)[0]
    )
  }, [condition])

  const renderCondition = (item, i) => (
    <div key={i} className={styles.conditionItem}>
      {i > 0 && renderTypeRadio()}
      <ConditionField
        additional={additional}
        canEdit={canEdit}
        condition={item}
        showWarning={!additional && isEqual(condition, EMPTY_CONDITION)}
        trigger={trigger}
        onChange={(field, value) => handleChangeCondition(i, field, value)}
        onRemove={() => handleRemoveCondition(i)}
      />
    </div>
  )

  const renderTypeRadio = () => (
    <div className={styles.conditionRadio}>
      <Radio
        id={uniqueId('and')}
        name={'condition_type'}
        value={false}
        checked={conditionType === CONDITION_TYPE_AND}
        label={t(CONDITION_TYPE_AND)}
        onChange={handleChangeConditionType}
      />
      <Radio
        id={uniqueId('or')}
        name={'condition_type'}
        value={true}
        checked={conditionType === CONDITION_TYPE_OR}
        label={t(CONDITION_TYPE_OR)}
        onChange={handleChangeConditionType}
      />
    </div>
  )

  const handleAddCondition = () => {
    setCondition(
      isEmpty(condition)
        ? EMPTY_CONDITION
        : {
            [conditionType]: oneCondition()
              ? [condition, EMPTY_CONDITION]
              : [...condition[conditionType], EMPTY_CONDITION],
          }
    )
  }

  const handleCopyCondition = () => {
    const newCondition =
      Object.keys(copyCondition).length > 1
        ? [copyCondition]
        : [...copyCondition[Object.keys(copyCondition)[0]]]

    setCondition(
      isEmpty(condition)
        ? copyCondition
        : {
            [conditionType]: oneCondition()
              ? [condition, ...newCondition]
              : [...condition[conditionType], ...newCondition],
          }
    )
  }

  const isCopyConditionDisabled = () =>
    isEmpty(copyCondition) ||
    (!oneCondition() &&
      copyCondition[Object.keys(copyCondition)[0]].length === 0)

  const handleRemoveCondition = i => {
    if (oneCondition()) {
      setCondition(additional ? {} : EMPTY_CONDITION)

      return
    }

    let newCondition = cloneDeep(condition)
    newCondition[conditionType].splice(i, 1)
    setCondition(
      newCondition[conditionType].length === 1
        ? newCondition[conditionType][0]
        : newCondition
    )
  }

  const handleChangeConditionType = e => {
    const newConditionType = JSON.parse(e.currentTarget.value)
      ? CONDITION_TYPE_OR
      : CONDITION_TYPE_AND
    setConditionType(newConditionType)
    setCondition({ [newConditionType]: Object.values(condition)[0] })
  }

  const handleChangeCondition = (i, field, value) => {
    const oldCondition = oneCondition()
      ? condition
      : condition[conditionType][i]
    const newCondition =
      field === 'field'
        ? { ...EMPTY_CONDITION, [field]: value }
        : { ...oldCondition, [field]: value }
    let newConditions = cloneDeep(condition)

    if (oneCondition()) {
      newConditions = newCondition
    } else {
      newConditions[conditionType][i] = newCondition
    }

    setCondition(newConditions)
  }

  return (
    <>
      <div className={styles.conditionBody}>
        {oneCondition()
          ? renderCondition(condition, 0)
          : condition[conditionType]
          ? condition[conditionType].map((item, i) => renderCondition(item, i))
          : null}
      </div>
      {canEdit && (
        <>
          <div className={styles.conditionFooter}>
            <Button.Add
              title={t('AddMoreConditions')}
              onClick={handleAddCondition}
            />
            {copyCondition && (
              <Button.Copy
                disabled={isCopyConditionDisabled()}
                title={t('CopyConditions')}
                onClick={handleCopyCondition}
              />
            )}
          </div>
          {copyCondition && isCopyConditionDisabled() && (
            <Warning
              noArrow
              text={t('CopyConditionWarning')}
              boxClassName={styles.warningBoxCondition}
              className={styles.warning}
            />
          )}
        </>
      )}
    </>
  )
}

export default Conditions
