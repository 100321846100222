// @flow

import {
  getApi,
  postApi,
  deleteApi,
  patchRaw,
  postRaw,
  putRaw,
} from './api.core'

export const addAutomation = params =>
  postApi('api/automation/automations/', params)

export const getAutomations = params =>
  getApi('api/automation/automations/', params)

export const deleteAutomation = id =>
  deleteApi(`api/automation/automations/${id}/`)

export const getAutomation = id => getApi(`api/automation/automations/${id}/`)

export const updateAutomation = (id, params) =>
  patchRaw(`api/automation/automations/${id}/`, params)

export const getAutomationFields = params =>
  getApi('api/automation/fields/', params)

export const getAutomationActions = id =>
  getApi(`api/automation/automations/${id}/actions/`)

export const addAutomationAction = (id, params) =>
  postRaw(`api/automation/automations/${id}/actions/`, params)

export const updateAutomationAction = (id, action_id, params) =>
  putRaw(`api/automation/automations/${id}/actions/${action_id}/`, params)

export const getAutomationHistory = params =>
  getApi('api/automation/automation-execution-events/', params)

export const getAutomationHistoryField = params =>
  getApi('api/automation/automation-execution-events/single-field/', params)

export const getTriggerEvents = () => getApi('api/automation/trigger-events')
