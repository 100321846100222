import { connect } from 'react-redux'
import { getLanguageCode } from '../../../utils/commonSelectors'
import SelectAsync from './SelectAsync'

const mapStateToProps = state => ({
  ...state.selectAsync,
  lang: getLanguageCode(state),
})

export default connect(mapStateToProps)(SelectAsync)
