// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import MailReplyForm from '../../Mail/MailReplyForm'
import NewSelectSimple from '../../NewSelectSimple'
import Checkbox from '../../Checkbox'

function getOptions(optionValues, t) {
  return optionValues.map(value => ({ label: t(value), value }))
}

const MarketplaceSelect = props => {
  const {
    value,
    marketplaceCc,
    marketplaceTitle,
    marketplaceText,
    id,
    marketplaceSendMessage,
  } = props

  const { t } = useTranslation('RequestConstructor')
  const marketplaceActions = useSelector(
    state => state.init.marketplace_actions
  ) || ['noop', 'add_contractor']
  const options = getOptions(marketplaceActions, t)
  const option = options.find(option => option.value === value) || options[0]

  const getList = list => list.map(i => ({ name: i }))

  return (
    <div style={{ position: 'relative', paddingTop: '30px', zIndex: 0 }}>
      <div
        style={{
          borderTop: '1px solid #ccc',
          position: 'absolute',
          top: '10px',
          right: '-15px',
          left: '-15px',
        }}
      ></div>
      <div className='request-constructor__card--request-item-title'>
        {t('SelectMarketPlaceAction')}:
      </div>
      <NewSelectSimple
        isBottom
        options={options}
        value={option}
        onChange={props.onChange}
      />
      <Checkbox
        disabled={option.value === 'noop'}
        text={t('SendMessageToProviders')}
        checked={marketplaceSendMessage}
        onChange={props.changeMessageSending}
      />
      {marketplaceSendMessage && (
        <MailReplyForm
          creating
          onlyManualFileRemove
          isToHided
          isCCShown
          showAddButton
          renderAdditional={props.renderAdditional}
          working={option.value === 'noop'}
          uuid={`mp-${id}`}
          values={{
            cc: getList(marketplaceCc || []),
            title: marketplaceTitle,
            message: marketplaceText,
          }}
          onWysiwygFocus={props.onWysiwygFocus}
          onUpdate={props.updateMarketPlaceForm}
        />
      )}
    </div>
  )
}

export default MarketplaceSelect
