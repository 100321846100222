// @flow

import { all, take, put, call, spawn, takeEvery } from 'redux-saga/effects'
import cookies from '../utils/cookies'

import companyLogo from '../components/CompanyLogo/CompanyLogo.saga'
import filesUpload from '../containers/FilesUpload/FilesUpload.saga'
import postCreating from '../components/Post/PostCreating/PostCreating.saga'
import messages from '../containers/Messages/Messages.saga'
import ThreadsList from '../containers/Mail/ThreadsList.saga'
import mailThread from '../containers/Mail/MailThread/MailThread.saga'
import chatroom from '../containers/Chatroom/Chatroom.saga'
import usersuggest from '../components/UserSuggest/UserSuggest.saga'
import sharedRequest from '../components/SharedRequest/SharedRequest.saga'
import checklist from '../components/Checklist/Checklist.saga'
import requestTranslate from '../components/Request/RequestTranslate/RequestTranslate.saga'
import labels from '../components/modals/Labels/LabelsPopup.saga'
import companyRegistration from '../components/CompanyRegistration/CompanyRegistration.saga'
import convertToRequestPopup from '../components/modals/ConvertToRequestPopup/ConverToRequestPopup.saga'
import attachToRequestPopup from '../components/modals/AttachToRequestPopup/AttachToRequestPopup.saga'
import demoDataStripe from '../components/WarningStripe/WarningStripe.saga'
import mailSearch from '../components/MailSearch/MailSearch.saga'

import companyView from '../components/CompanyView/CompanyView.saga'
import init from '../components/init/init.saga'
import login from '../components/Auth/Login/Login.saga'
import headerSearchSaga from '../components/HeaderSearch/HeaderSearch.saga'

import requestView from '../containers/RequestView/RequestView.saga'
import requestCreate from '../components/RequestCreate/RequestCreate.saga'
import requestList from '../components/Request/RequestList/RequestList.saga'
import flatDwellers from '../components/Flat/FlatDwellers/FlatDwellers.saga'
import flatList from '../components/Flat/FlatList/FlatList.saga'
import flatView from '../containers/FlatView/FlatView.saga'
import residentList from '../components/Residents/ResidentList/ResidentList.saga'
import like from '../components/Like/Like.saga'
import widgetMayKnowSaga from '../components/widgets/WidgetMayKnow/WidgetMayKnow.saga'
import WidgetFiles from '../components/widgets/WidgetFiles/WidgetFiles.saga'
import WidgetParticipantsList from '../components/widgets/WidgetParticipantsList/WidgetParticipantsList.saga'
import widgetLastReg from '../components/widgets/WidgetLastReg/WidgetLastReg.saga'
import profile from '../containers/Profile/Profile.saga'
import profileInfo from '../components/Profile/ProfileInfo/ProfileInfo.saga'
import publicBuildingPage from '../containers/PublicBuildingPage/PublicBuildingPage.saga'
import ImportFilesTableSaga from '../components/ImportFilesTable/ImportFilesTable.saga'
import ImportMapTableSaga from '../components/ImportMapTable/ImportMapTable.saga'
import selectAsync from '../components/Select/SelectAsync/SelectAsync.saga'
import selectChatMember from '../components/Select/SelectChatMember/SelectChatMember.saga'
import addFlatPopup from '../components/modals/AddFlatPopup/AddFlatPopup.saga'
import resendInvitePopUp from '../components/modals/ResendInvitePopUp/ResendInvitePopUp.saga'
import addManagerPopUp from '../components/modals/AddManagerPopUp/AddManagerPopUp.saga'
import buildingList from '../components/Building/BuildingList/BuildingList.saga'
import buildingCreate from '../components/BuildingCreate/BuildingCreate.saga'
import buildingView from '../components/BuildingView/BuildingView.saga'
import buildingFlats from '../components/Building/BuildingFlats/BuildingFlats.saga'
import buildingDwellers from '../components/Building/BuildingDwellers/BuildingDwellers.saga'
import postView from '../components/PostView/PostView.saga'
import userListInfo from '../components/User/UserListInfo/UserListInfo.saga'
import userList from '../components/User/UserList/UserList.saga'
import settingsCorporate from '../components/SettingsCorporate/SettingsCorporate.saga'
import settingsPersonal from '../components/SettingsPersonal/SettingsPersonal.saga'
import confirmEmail from '../containers/ConfirmEmail/ConfirmEmail.saga'
import friends from '../components/Friends/Friends.saga'
import friendsNotifications from '../components/FriendsNotifications/FriendsNotifications.saga'
import friendsRequests from '../components/FriendsRequests/FriendsRequests.saga'
import FAQ from '../components/FAQ/Faq.saga'
import igel from '../containers/Integrations/Igel/Igel.saga'
import realData from '../containers/Integrations/RealData/RealData.saga'
import addRequestCategoryPopup from '../components/modals/AddRequestCategoryPopup/AddRequestCategoryPopup.saga'
import requestConstructor from '../containers/RequestConstructor/RequestConstructor.saga'
import requestConstructorBlock from '../containers/RequestConstructorBlock/RequestConstructorBlock.saga'
import requestConstructorCards from '../containers/RequestConstructorCards/RequestConstructorCards.saga'
import { push } from 'connected-react-router'
import { LOGOUT } from '../components/Auth/Login/Login.actionTypes'
import { serverError } from '../components/Layout/Layout.actions'
import api from '../core/api'
import BrowserStorage from '../utils/browserStorage'
import { removeJWTtoken } from '../utils/jwt'
import companyBackground from '../components/Company/CompanyBackground/CompanyBackground.saga'
import requestFeedback from '../components/Request/RequestFeedback/RequestFeedback.saga'
import feedback from '../containers/Feedback/Feedback.saga'
import marketplaceSettings from '../containers/MarketplaceSettings/MarketplaceSettings.saga'
import newsList from '../containers/NewsList/NewsList.saga'
import activitiesWidget from '../components/widgets/ActivitiesWidget/ActivitiesWidget.sagas'
import mailsList from '../containers/Mail/MailsList/MailsList.saga'
import audienceList from '../components/modals/AudienceList/AudienceList.saga'
import translations from '../components/translations/translations.saga'

function startRestSagas(...sagas) {
  return sagas.map(saga => spawn(saga))
}

function* watchLogout() {
  yield takeEvery(LOGOUT, logout)
}

function* logout({ pathName }) {
  try {
    yield call(api.login.logout)
    yield call([BrowserStorage, BrowserStorage.remove], 'token')
    yield call(removeJWTtoken)
    yield call([cookies, cookies.removeItem], 'marketplace_token')
    yield call([cookies, cookies.removeItem], 'marketplace_url')
    yield call([cookies, cookies.removeItem], 'mpSessionId')

    yield put(push(pathName || '/login'))
  } catch (error) {
    yield put(serverError(error))
  }
}

export default function* rootSaga() {
  yield take('APP:INIT')
  yield all(
    startRestSagas(
      activitiesWidget,
      audienceList,
      companyLogo,
      filesUpload,
      postCreating,
      init,
      login,
      watchLogout,
      headerSearchSaga,
      requestView,
      requestCreate,
      requestList,
      flatDwellers,
      flatList,
      flatView,
      residentList,
      companyView,
      resendInvitePopUp,
      like,
      widgetMayKnowSaga,
      WidgetFiles,
      WidgetParticipantsList,
      profile,
      profileInfo,
      publicBuildingPage,
      ImportFilesTableSaga,
      ImportMapTableSaga,
      selectAsync,
      addFlatPopup,
      buildingList,
      buildingCreate,
      buildingView,
      buildingFlats,
      buildingDwellers,
      postView,
      userListInfo,
      userList,
      settingsCorporate,
      settingsPersonal,
      confirmEmail,
      widgetLastReg,
      friends,
      friendsNotifications,
      friendsRequests,
      FAQ,
      addManagerPopUp,
      messages,
      ThreadsList,
      mailThread,
      chatroom,
      usersuggest,
      sharedRequest,
      checklist,
      requestTranslate,
      labels,
      companyRegistration,
      convertToRequestPopup,
      attachToRequestPopup,
      demoDataStripe,
      selectChatMember,
      mailSearch,
      igel,
      realData,
      addRequestCategoryPopup,
      requestConstructor,
      requestConstructorBlock,
      requestConstructorCards,
      companyBackground,
      requestFeedback,
      feedback,
      marketplaceSettings,
      newsList,
      mailsList,
      translations
    )
  )
}
