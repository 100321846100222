// @flow

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'

import Modal from '../Modal'
import ModalBody from '../Modal/ModalBody'
import ModalCloseButton from '../Modal/ModalCloseButton'
import ModalHead from '../Modal/ModalHead'
import { useOverflow, useThemeColor } from '../../hooks'

import Thread from './Thread'
import { getRequestThreadInfo } from '../../core/api/api.request'

import styles from './EmailsCreatingModal.module.scss'

const ThreadModal = ({ onClose, ...props }) => {
  const { t } = useTranslation('Request')
  useOverflow()

  const themeColor = useThemeColor()

  const [audience, setAudience] = useState(null)
  const [subject, setSubject] = useState('')
  const [error, setError] = useState(false)

  useEffect(() => {
    const { requestId, uuid } = props
    getRequestThreadInfo(requestId, uuid)
      .then(({ audience, title }) => {
        setSubject(title)
        setAudience(audience)
      })
      .catch(err => setError(err))
  }, [])

  if (error) {
    return <Redirect to='/404' />
  }

  const threadProps = {
    ...props,
    subject,
    audience,
    isModal: true,
    onCloseModal: onClose,
  }

  return (
    <Modal isOpen className={styles.modal} onRequestClose={onClose}>
      <ModalHead title={t('RequestThreadTitle')} className={styles.modalHead} />
      <ModalCloseButton onClose={onClose} />
      <ModalBody
        style={{
          maxHeight: '600px',
          overflowY: 'auto',
          '--themeColor': themeColor,
        }}
      >
        <Thread {...threadProps} />
      </ModalBody>
    </Modal>
  )
}

export default ThreadModal
