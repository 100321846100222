//@flow

import BrowserStorage from './browserStorage'
import { getJWTtoken, refreshJWTtoken } from '../core/api/api.login'

const ACCESS_TOKEN_EXPIRE = 60 * 60 * 1000 //1 hour jwt token expire
let checkTokenTimeout = null

export const checkJWTtoken = () => {
  if (
    !BrowserStorage.get('jwt_access_token') ||
    !BrowserStorage.get('jwt_refresh_token')
  ) {
    getJWTtoken().then(data => {
      setTokenCallback(data)
    })
  } else {
    checkRefreshToken()
  }
}

export const removeJWTtoken = () => {
  BrowserStorage.remove('jwt_access_token')
  BrowserStorage.remove('jwt_access_token_ts')
  BrowserStorage.remove('jwt_refresh_token')
}

const checkRefreshToken = () => {
  const access_ts = BrowserStorage.get('jwt_access_token_ts')

  if (!access_ts || Date.now() >= +access_ts) {
    clearTimeout(checkTokenTimeout)
    const access = BrowserStorage.get('jwt_access_token')
    const refresh = BrowserStorage.get('jwt_refresh_token')
    refreshJWTtoken({ access, refresh })
      .then(data => {
        setTokenCallback(data)
      })
      .catch(() => {
        getJWTtoken().then(data => {
          setTokenCallback(data)
        })
      })
  }
}

const setTokenCallback = data => {
  const { access, refresh } = data
  BrowserStorage.set('jwt_access_token', access)

  if (refresh) {
    BrowserStorage.set('jwt_refresh_token', refresh)
  }

  BrowserStorage.set('jwt_access_token_ts', Date.now() + ACCESS_TOKEN_EXPIRE)
  checkTokenTimeout = setTimeout(checkRefreshToken, ACCESS_TOKEN_EXPIRE)
  window.addEventListener('beforeunload', () => clearTimeout(checkTokenTimeout))
}
