// @flow

import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import { useRouteMatch } from 'react-router-dom'
import type { Node } from 'react'

import Modal from '../Modal'
import EmptyList from '../EmptyList'
import { deleteThread, getRequestThreads } from '../../core/api/api.request'
import { updateRequest } from '../../core/api/api.massAction'
import Button from '../Button'
import Content from '../Content'
import Loader from '../Loader'
import NewTable from '../NewTable'
import Toolbox from '../Toolbox'
import ToolboxCell from '../Users/UserList/Toolbox/ToolboxCell'
import TopPagination from '../Pagination/TopPagination'
import BottomPagination from '../Pagination/BottomPagination'
import EmailsCreatingModal from './EmailsCreatingModal'
import EmailsTableBody from './EmailsTableBody'
import Thread from './Thread'
import ThreadModal from './ThreadModal'
import AudienceChangeModal from './AudienceChangeModal'
import NotesChangeModal from './NotesChangeModal'
import NewSelectSimple from '../NewSelectSimple'
import { useSelected } from '../../hooks/useSelected'
import ConfirmationPopup from '../modals/ConfirmationPopup'
import ScrollButtonEmails from './ScrollButtonEmails'
import SelectCustom from '../Select/SelectCustom'
import AttachToRequestPopup from '../modals/AttachToRequestPopup'

import {
  DWELLER_AUDIENCE,
  MANAGER_AUDIENCE,
  PROVIDER_AUDIENCE,
} from '../../constants'
import ActivitiesChangeModal from './ActivitiesChangeModal'

import { MAIL_SEARCH_SELECT_USERS } from '../MailSearch/MailSearch.actionTypes'

import { checkPending } from './EmailPending'

import styles from './Emails.module.scss'
import RouterPrompt from '../Mail/RouterPrompt'

type Props = {
  audience?: string,
  buildingId?: number,
  canMoveThread?: boolean,
  directoryId: number,
  files: Array<Object>,
  isReloading?: boolean,
  notes?: ?string,
  requestId: number,
}

const Emails = (props: Props): Node => {
  const {
    requestId,
    canMoveThread,
    buildingId,
    audience,
    directoryId,
    selectedProviders,
    selectedCustomers,
    isReloading,
    scope,
    object,
    flatId,
  } = props
  const { t } = useTranslation('Request')
  const dispatch = useDispatch()
  const match = useRouteMatch()

  const [loading, setLoading] = useState(false)
  const [isModalOpen, openModal] = useState(false)
  const [isAudienceModalOpen, openAudienceModal] = useState(null)
  const [isActivitiesModalOpen, openActivitiesModal] = useState(null)
  const [isNotesModalOpen, openNotesModal] = useState(null)
  const [isMoveModalOpen, openMoveModal] = useState(null)
  const [isThreadModalOpen, openThreadModal] = useState(null)
  const [actionModal, setActionModal] = useState(null)
  const [threads, setThreads] = useState([])
  const [page, setPage] = useState(1)
  const [meta, setMeta] = useState({})
  const [uuid, setUuid] = useState(null)
  const [subject, setSubject] = useState(null)
  const [threadAudience, setAudience] = useState(null)
  const [selectedAudience, setSelectedAudience] = useState(MANAGER_AUDIENCE)
  const [selectedItems, changeSelected, setSelected, isAllSelected] =
    useSelected()
  const emailsList = useRef(null)

  useEffect(() => {
    if (isReloading) {
      loadThreads()
    }
  }, [isReloading])

  useEffect(() => {
    if (loading && !uuid) {
      loadThreads()
    }
  }, [loading])

  useEffect(() => {
    if (match.params?.uuid) {
      openThreadModal(true)
    } else {
      openThreadModal(null)
    }
  }, [match])

  useEffect(() => {
    setLoading(true)
  }, [page])

  const handlePage = page => {
    setPage(page)
    emailsList.current.scrollIntoView()
  }

  const loadThreads = () => {
    const params = Object.assign(
      {},
      { page },
      audience ? { audience } : undefined
    )

    getRequestThreads(requestId, params)
      .then(data => {
        setThreads(data.results.objects)
        setMeta(data.meta)

        if (selectedItems.length) {
          setSelected([])
        }
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (threads.length > 0) {
      checkPending(threads, () => setLoading(true))
    }
  }, [threads])

  const selectAudienceEmails = value => {
    if (value === PROVIDER_AUDIENCE || value === DWELLER_AUDIENCE) {
      dispatch({
        type: MAIL_SEARCH_SELECT_USERS,
        selectedUsers:
          value === PROVIDER_AUDIENCE ? selectedProviders : selectedCustomers,
      })
    }
  }

  const handleCreate = () => {
    selectAudienceEmails(audience)
    openModal(true)
  }
  const handleModalClose = () => openModal(false)
  const handleAudienceModalClose = () => openAudienceModal(null)
  const handleNotesModalClose = () => openNotesModal(null)
  const handleActivitiesModalClose = () => openActivitiesModal(null)
  const handleChange = option => {
    setSelectedAudience(option.value)
    selectAudienceEmails(option.value)
    openModal(true)
  }
  const handleMoveModalClose = () => openMoveModal(null)
  const handleThreadModalClose = () => {
    openThreadModal(null)
    dispatch(push(`/request/${requestId}/emails/`))
    emailsList.current.scrollIntoView()
  }

  if (loading) {
    return <Loader text={false} type='big' />
  }

  const options = [
    { label: t('ToProviders'), value: PROVIDER_AUDIENCE },
    { label: t('ToDwellers'), value: DWELLER_AUDIENCE },
    { label: t('ToManagers'), value: MANAGER_AUDIENCE },
  ]

  const hideActionModal = () => setActionModal(null)

  const removeThreads = async () => {
    return Promise.all(
      selectedItems.map(item => {
        return deleteThread(requestId, item).then(data => data)
      })
    )
  }

  const handleMassRemove = async () => {
    hideActionModal()

    await removeThreads()

    if (page > 1) {
      setPage(1)
    } else {
      setLoading(true)
    }
  }

  const openMassModal = () => {
    setActionModal(
      <ConfirmationPopup
        confirm={t('EmailThreadsRemoveButton')}
        title={t('EmailThreadsRemoveTitle')}
        text={t('ConfirmEmailThreadssRemoving')}
        onClose={hideActionModal}
        onOk={handleMassRemove}
      />
    )
  }

  const openMassRemoveModal = () => {
    openMassModal()
  }

  const handleRemove = id => () => {
    hideActionModal()

    deleteThread(requestId, id).then(() => {
      if (page > 1) {
        setPage(1)
      } else {
        setLoading(true)
      }
    })
  }

  const openRemove = id => {
    setActionModal(
      <ConfirmationPopup
        confirm={t('ThreadRemoveButton')}
        title={t('ThreadRemoveTitle')}
        text={t('ConfirmThreadRemoving')}
        onClose={hideActionModal}
        onOk={handleRemove(id)}
      />
    )
  }

  const openRemoveModal = id => () => {
    openRemove(id)
  }

  const massUpdateStarred = starred => {
    updateRequest({
      model: 'request_thread',
      model_pks: JSON.stringify(selectedItems),
      patch: { starred },
    }).then(() => {
      setSelected([])
      setLoading(true)
    })
  }

  const addStar = () => {
    massUpdateStarred(true)
  }

  const removeStar = () => {
    massUpdateStarred(false)
  }

  const getMassActionOptions = () => {
    return [
      {
        value: 'addStar',
        label: t('Mail:AddStar'),
        handler: () => addStar(),
        icon: 'starFilled',
      },
      {
        value: 'removeStar',
        label: t('Mail:RemoveStar'),
        handler: () => removeStar(),
        icon: 'newStar',
      },
    ]
  }

  const isShowScrollButton = threads?.length > 10

  return (
    <div ref={emailsList} className={styles.wrapEmail}>
      {uuid ? (
        <>
          <RouterPrompt
            when={props.changedValues}
            navigate={path => {
              props.history.push(path)
            }}
            shouldBlockNavigation={() => {
              return true
            }}
            isModal={true}
            openModal={props.showSuggestModal}
            closeMailModal={() => {
              props.canOpenTab()
            }}
            setShowCanLeave={() => props.setShowSuggestModal(null)}
          />
          <Thread
            scope={scope}
            object={object}
            uuid={uuid}
            subject={subject}
            requestId={requestId}
            buildingId={buildingId}
            audience={threadAudience}
            directoryId={directoryId}
            setSubject={setSubject}
            setUuid={setUuid}
            setChangedValues={props.setChangedValues}
            changedValues={props.changedValues}
            loadThreads={loadThreads}
            flatId={flatId}
          />
        </>
      ) : (
        <>
          <Content>
            <Toolbox
              showSelectAllCheckbox
              itemValueKey='uuid'
              checked={isAllSelected(threads)}
              selected={!!selectedItems.length && !isAllSelected(threads)}
              items={threads}
              setSelected={setSelected}
            >
              {selectedItems.length ? (
                <>
                  <ToolboxCell>
                    <Button.Remove
                      icon='remove'
                      iconClassName={styles.icon}
                      onClick={openMassRemoveModal}
                    >
                      {t('EmailThreadsRemoveButton')}
                    </Button.Remove>
                  </ToolboxCell>
                  <ToolboxCell>
                    <SelectCustom
                      options={getMassActionOptions()}
                      onChange={opt => opt.handler()}
                    >
                      {t('Actions')}
                    </SelectCustom>
                  </ToolboxCell>
                </>
              ) : (
                <ToolboxCell>
                  {audience ? (
                    <Button.Save onClick={handleCreate}>
                      {t('AddEmailMessage')}
                    </Button.Save>
                  ) : (
                    <NewSelectSimple
                      buttonView='contained'
                      buttonClass={styles.button}
                      options={options}
                      placeholder={t('AddEmailMessage')}
                      name='create-thread'
                      clearable={false}
                      onChange={handleChange}
                    />
                  )}
                </ToolboxCell>
              )}
              <ToolboxCell isRight>
                <TopPagination
                  classes='pager pager--large pager--default-2'
                  meta={meta}
                  setPage={setPage}
                />
              </ToolboxCell>
            </Toolbox>
            <NewTable loading={loading}>
              <EmailsTableBody
                object={object}
                scope={scope}
                selectedItems={selectedItems}
                handleChange={changeSelected}
                setActionModal={setActionModal}
                threads={threads}
                setUuid={setUuid}
                setSubject={setSubject}
                setAudience={setAudience}
                requestId={requestId}
                setLoading={setLoading}
                openAudienceModal={openAudienceModal}
                openActivitiesModal={openActivitiesModal}
                openNotesModal={openNotesModal}
                openRemoveModal={openRemoveModal}
                openThreadModal={openThreadModal}
                openMoveModal={canMoveThread && openMoveModal}
                setActivity={props.setActivity}
              />
            </NewTable>
            <BottomPagination
              classes='paginator-2'
              setPage={handlePage}
              meta={meta}
              nodeToScroll={emailsList}
            />
            {isShowScrollButton && (
              <ScrollButtonEmails
                isCanShow={isShowScrollButton}
                emailsListRef={emailsList}
              />
            )}
            {!threads.length && !loading && (
              <EmptyList icon='email' title={t('EmailsNotFound')} />
            )}
          </Content>
          {isAudienceModalOpen && (
            <AudienceChangeModal
              isOpen={!!isAudienceModalOpen}
              requestId={requestId}
              thread={isAudienceModalOpen}
              reloadThreads={setLoading}
              onClose={handleAudienceModalClose}
            />
          )}
          {isNotesModalOpen && (
            <NotesChangeModal
              isOpen={!!isNotesModalOpen}
              requestId={requestId}
              thread={isNotesModalOpen}
              reloadThreads={setLoading}
              onClose={handleNotesModalClose}
            />
          )}
          {isModalOpen && (
            <EmailsCreatingModal
              directoryId={directoryId}
              isOpen={isModalOpen}
              requestId={requestId}
              buildingId={buildingId}
              reloadThreads={setLoading}
              audience={audience || selectedAudience}
              flatId={flatId}
              onClose={handleModalClose}
            />
          )}
          {isActivitiesModalOpen && (
            <ActivitiesChangeModal
              requestId={requestId}
              thread={isActivitiesModalOpen}
              setLoading={setLoading}
              onClose={handleActivitiesModalClose}
            />
          )}
          {isMoveModalOpen && (
            <Modal
              isOpen={!!isMoveModalOpen}
              onRequestClose={handleMoveModalClose}
            >
              <AttachToRequestPopup
                move
                emailMessageId={isMoveModalOpen}
                requestIds={requestId}
                onClose={handleMoveModalClose}
                onSave={() => setLoading(true)}
              />
            </Modal>
          )}
          {isThreadModalOpen && (
            <ThreadModal
              scope={scope}
              object={object}
              uuid={match.params.uuid}
              subject={subject}
              requestId={requestId}
              buildingId={buildingId}
              audience={threadAudience}
              directoryId={directoryId}
              setSubject={setSubject}
              setUuid={setUuid}
              setChangedValues={props.setChangedValues}
              changedValues={props.changedValues}
              loadThreads={loadThreads}
              flatId={flatId}
              onClose={handleThreadModalClose}
            />
          )}
          <Modal isOpen={!!actionModal} onRequestClose={hideActionModal}>
            {actionModal}
          </Modal>
        </>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  ...state.mailSearch,
})

export default connect(mapStateToProps)(Emails)
