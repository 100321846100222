import React, { useEffect, useState, useRef } from 'react'
import { get, pick, partial } from 'lodash-es'
import { useDispatch } from 'react-redux'
import { useError } from '../useError'
import { useDropzone } from 'react-dropzone'
import {
  formatFilesToBase64,
  getFullThumbnailPath,
  uploadFile,
} from '../../../utils/file'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { SimpleBlock } from '../../Block'

import {
  ACCOUNT_TYPE,
  AUTO_ARCHIVE_ON_ATTACH,
  DEFAULT_VALUES,
  EMAIL,
  DISPLAY_NAME,
  INSERT_SIGNATURE_BEFORE,
  MARK_AS_READ,
  KEYS,
  SIGNATURE,
  REPLY_TO,
  WORK_AS_EMAIL_CLIENT,
  THUMBNAIL_SIZE,
  CONVERSATION_VIEW,
  SIG_IMAGE_MAX_SIZE,
} from './AccountSettings.constants'

import {
  reconnectAccount,
  setAccountSettingsMail,
} from '../../../core/api/api.mail'
import { replace } from 'connected-react-router'
import SectionHeader from '../../SectionHeader'
import Field from '../Field'
import Button from '../../Button'
import styles from './Accountsettings.module.scss'
import Icon from '../../Icon'
import NewSelectSimple from '../../NewSelectSimple'
import { Editor } from '@tinymce/tinymce-react'
import { getFile } from '../../../core/api/api.file'
import Checkbox from '../../Checkbox'
import {
  noramlizeValidationValues,
  compareProvideObjectStringify,
} from '../../../utils/utils'
import { globalModalError } from '../../Layout/Layout.actions'

const REACT_DROPZONE_ERROR_CODES = {
  'file-too-large': (t, name, size) => t('FileSize', { name, size }),
  'file-invalid-type': t => t('WrongFileType'),
}

const AccountSettings = props => {
  const { accountId, initialParams, needReconnect, corporate } = props
  const { t } = useTranslation('EmailIntegrationNylas')
  const { t: errorT } = useTranslation('Errors')
  const id = get(initialParams, 'id')
  const status = get(initialParams, ['status', 'status'])
  const message = get(initialParams, ['status', 'message'])
  const can_work_as_email_client = get(initialParams, [
    'permissions',
    'can_work_as_email_client',
  ])

  const can_edit_display_name = get(initialParams, [
    'permissions',
    'can_edit_display_name',
  ])

  const can_reconnect = get(initialParams, ['permissions', 'can_reconnect'])

  const [isWorking, setWorking] = useState(false)
  const [setError] = useError()

  const [isHover, setHover] = useState(false)
  const handleMouseEnter = () => setHover(true)
  const handleMouseLeave = () => setHover(false)

  const { open, acceptedFiles, getInputProps, fileRejections } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    maxSize: SIG_IMAGE_MAX_SIZE * 1024 * 1024,
    accept: 'image/jpeg,image/png',
  })

  const interval = useRef(null)

  useEffect(() => {
    clearInterval(interval.current)
  }, [])

  const editorRef = useRef(null)

  useEffect(() => {
    if (!acceptedFiles || !acceptedFiles.length || !editorRef.current) {
      return
    }

    formatFilesToBase64(acceptedFiles).then(base64 => {
      const date = new Date()
      const time = date.getTime()

      const blobCache = editorRef.current.editorUpload.blobCache
      const blobInfo = blobCache.create(
        String(time),
        acceptedFiles[0],
        base64[0].origin
      )

      blobCache.add(blobInfo)

      editorRef.current.insertContent(
        editorRef.current.dom.createHTML('img', {
          src: blobInfo.blobUri(),
          maxWidth: `${THUMBNAIL_SIZE}px`,
        })
      )
    })
  }, [acceptedFiles])

  useEffect(() => {
    if (fileRejections?.length) {
      let errors = fileRejections.map(f => {
        const error = get(f, ['errors', '0', 'code'])
        const name = get(f, ['file', 'name'], '')

        if (error) {
          return REACT_DROPZONE_ERROR_CODES[error](
            errorT,
            name,
            SIG_IMAGE_MAX_SIZE
          )
        }

        return null
      })

      const errorText = errors.reduce((acc, error) => {
        return acc + error + '<br/>'
      }, '')

      dispatch(globalModalError(errorText, errorT('File')))
    }
  }, [fileRejections])

  const dispatch = useDispatch()
  const getInitialValues = (initialParams, defaultValues, keys) => {
    return initialParams
      ? noramlizeValidationValues(initialParams, keys)
      : defaultValues
  }
  const initialValues = getInitialValues(initialParams, DEFAULT_VALUES, KEYS)

  const formik = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
    initialValues: {
      ...initialValues,
    },
    validationSchema: Yup.object({
      [EMAIL]: Yup.string(),
      [REPLY_TO]: Yup.string().email(t('Common:EnterValidEmailErrorText')),
      [SIGNATURE]: Yup.string(),
      [INSERT_SIGNATURE_BEFORE]: Yup.boolean(),
      [DISPLAY_NAME]: Yup.string(),
      [MARK_AS_READ]: Yup.boolean(),
      [WORK_AS_EMAIL_CLIENT]: Yup.boolean(),
      [CONVERSATION_VIEW]: Yup.boolean(),
      [AUTO_ARCHIVE_ON_ATTACH]: Yup.boolean(),
    }),
    onSubmit: values => {
      setWorking(true)
      let params = pick(
        values,
        corporate
          ? [
              SIGNATURE,
              DISPLAY_NAME,
              MARK_AS_READ,
              CONVERSATION_VIEW,
              INSERT_SIGNATURE_BEFORE,
              AUTO_ARCHIVE_ON_ATTACH,
              REPLY_TO,
              WORK_AS_EMAIL_CLIENT,
            ]
          : [
              SIGNATURE,
              MARK_AS_READ,
              CONVERSATION_VIEW,
              INSERT_SIGNATURE_BEFORE,
              AUTO_ARCHIVE_ON_ATTACH,
              REPLY_TO,
              WORK_AS_EMAIL_CLIENT,
            ]
      )

      let api = partial(setAccountSettingsMail, id)

      api(params)
        .then(data => {
          if (accountId && props.setLoading) {
            props.setLoading(true)
          } else {
            dispatch(replace(`/settings/email/${data.id}`))
          }
        })
        .catch(error => setError(error))
        .finally(() => setWorking(false))
    },
  })

  const handleSignature = value => {
    const {
      status,
      initialValues,
      values,
      setFieldValue,
      resetForm,
      setStatus,
    } = formik

    if (status || status === '') {
      setFieldValue(SIGNATURE, value)
    }

    if (status === value) {
      // eslint-disable-next-line no-unused-vars
      const { signature, ...otherInitialProp } = initialValues
      // eslint-disable-next-line no-unused-vars
      const { signature: currentSignature, ...otherValuesProp } = values

      if (compareProvideObjectStringify(otherInitialProp, otherValuesProp)) {
        resetForm()
      }

      setStatus(value)
    }
  }

  const handleInit = (e, editor) => {
    editorRef.current = editor
  }

  const handleSelectMark = option => {
    const { value } = option
    formik.setFieldValue(MARK_AS_READ, value)
  }

  const handleSelectConversation = option => {
    const { value } = option
    formik.setFieldValue(CONVERSATION_VIEW, value)
  }

  const markAsReadOptions = [
    { value: true, label: t('isMarkAsRead') },
    { value: false, label: t('isNotMarkAsRead') },
  ]

  const conversationOptions = [
    { value: true, label: t('DisplayConversation') },
    { value: false, label: t('DisplaySeparateEmail') },
  ]

  const markAsReadOption = markAsReadOptions.find(
    option => option.value === formik.values[MARK_AS_READ]
  )

  const conversationOption = conversationOptions.find(
    option => option.value === formik.values[CONVERSATION_VIEW]
  )

  const handleUpload = () => {
    open()
  }
  const handleReconnect = () => {
    setWorking(true)
    reconnectAccount(accountId)
      .then(({ auth_url }) => {
        props.setLoading(true)
        window.location.assign(auth_url)
      })
      .catch(error => setError(error))
      .finally(() => setWorking(false))
  }
  const handleFocus = () => {
    const content = editorRef.current.getContent()

    formik.setStatus(content)
  }

  return (
    <SimpleBlock working={isWorking}>
      <SectionHeader header='true' text={t('AccountSettings')} />
      <form name='accountSettings' onSubmit={formik.handleSubmit}>
        <div className='settings-personal__form'>
          <Field
            disabled
            label='emailAddress'
            name={EMAIL}
            value={formik.values[EMAIL]}
          />
          <Field
            label='replyTo'
            name={REPLY_TO}
            value={formik.values[REPLY_TO]}
            error={formik.errors[REPLY_TO]}
            handleChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Field
            disabled
            label='AccountType'
            name={ACCOUNT_TYPE}
            value={corporate ? t('Corporate') : t('Private')}
          />
          <Field error={formik.errors[MARK_AS_READ]} label='markAsRead'>
            <NewSelectSimple
              className={styles.select}
              options={markAsReadOptions}
              value={markAsReadOption}
              name={MARK_AS_READ}
              clearable={false}
              onChange={handleSelectMark}
            />
          </Field>
          {can_edit_display_name && (
            <Field
              label='displayName'
              name={DISPLAY_NAME}
              error={formik.errors[DISPLAY_NAME]}
              value={formik.values[DISPLAY_NAME]}
              handleChange={formik.handleChange}
            />
          )}
          <Field
            error={formik.errors[WORK_AS_EMAIL_CLIENT]}
            label='WorkAsEmailClient'
          >
            <Checkbox
              name={WORK_AS_EMAIL_CLIENT}
              checked={formik.values[WORK_AS_EMAIL_CLIENT]}
              disabled={!can_work_as_email_client}
              onChange={formik.handleChange}
            />
            <span
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Icon id='about' className={styles.icon} />
              {isHover && (
                <div className={styles.tooltip}>
                  <div />
                  <span>{t('WorkAsEmailClientNotes')}</span>
                </div>
              )}
            </span>
          </Field>
          <Field error={formik.errors[SIGNATURE]} label='signature'>
            <div className='textarea-2 textarea-2--large textarea-2--block textarea-2--default'>
              <Editor
                tinymceScriptSrc='https://files.idwell.com/js/tinymce/5.10.8/tinymce.min.js'
                value={formik.values[SIGNATURE]}
                init={{
                  menubar: false,
                  element_format: 'html',
                  elementpath: false,
                  entity_encoding: 'raw',
                  min_height: 200,
                  max_height: 400,
                  object_resizing: false,
                  setup: function (editor) {
                    editor.ui.registry.addButton('customImageUploading', {
                      icon: 'image',
                      tooltip: 'Insert image',
                      onAction: () => {
                        handleUpload()
                      },
                    })
                  },
                  fontsize_formats: '8px 9px 10px 11px 12px 14px 16px',
                  content_style:
                    'body { font-family: sans-serif; font-size: 14px; }' +
                    'p { line-height: 16px; margin: 0; }',
                  font_formats:
                    'Arial=Arial,sans-serif; Calibri=calibri,sans-serif; Monospace=monospace; Plus Jakarta Sans=Plus Jakarta Sans,sans-serif; Sans Serif=sans-serif; Serif=serif; Times New Roman=Times New Roman,sans-serif; Verdana=Verdana,sans-serif',
                  images_upload_handler: (blobInfo, success) => {
                    const checkFile = id => {
                      getFile(id, {
                        thumbnail_resolution: THUMBNAIL_SIZE,
                      }).then(data => {
                        if (data.checking) {
                          interval.current = setTimeout(
                            () => checkFile(id),
                            1000
                          )
                        } else {
                          clearTimeout(interval.current)
                          setWorking(false)
                          success(getFullThumbnailPath(data, THUMBNAIL_SIZE))
                        }
                      })
                    }
                    setWorking(true)
                    uploadFile({
                      origin: [blobInfo.blob()],
                      type: 6,
                      name: blobInfo.filename(),
                    }).then(data => {
                      checkFile(data.id)
                    })
                  },
                  quickbars_insert_toolbar: '',
                  quickbars_selection_toolbar: '',
                  plugins: ['autoresize', 'image', 'quickbars'],
                  toolbar:
                    'fontselect fontsizeselect | bold italic underline forecolor | customImageUploading',
                }}
                onEditorChange={handleSignature}
                onFocus={handleFocus}
                onInit={handleInit}
              />
              <input {...getInputProps()} />
            </div>
          </Field>
          <Field
            error={formik.errors[INSERT_SIGNATURE_BEFORE]}
            label='SignatureBefore'
          >
            <Checkbox
              text={t('InsertSignatureBefore')}
              name={INSERT_SIGNATURE_BEFORE}
              checked={formik.values[INSERT_SIGNATURE_BEFORE]}
              onChange={formik.handleChange}
            />
          </Field>
          <Field
            error={formik.errors[CONVERSATION_VIEW]}
            label='EmailConversation'
            helpText='EmailConversationHelp'
          >
            <NewSelectSimple
              className={styles.select}
              options={conversationOptions}
              value={conversationOption}
              name={CONVERSATION_VIEW}
              clearable={false}
              onChange={handleSelectConversation}
            />
          </Field>
          {!formik.values[CONVERSATION_VIEW] && (
            <Field
              error={formik.errors[AUTO_ARCHIVE_ON_ATTACH]}
              label='AutoArchiveLabel'
            >
              <Checkbox
                text={t('AutoArchiveMessage')}
                name={AUTO_ARCHIVE_ON_ATTACH}
                checked={formik.values[AUTO_ARCHIVE_ON_ATTACH]}
                onChange={formik.handleChange}
              />
            </Field>
          )}
        </div>
        <div className='settings-personal__submit'>
          {(formik.dirty || isWorking || !id || !needReconnect) && (
            <>
              <Button.Save
                type='submit'
                working={isWorking}
                disabled={!formik.dirty}
              >
                {t('Common:Save')}
              </Button.Save>
              <Button.Cancel
                disabled={!formik.dirty}
                onClick={formik.resetForm}
              >
                {t('Common:Cancel')}
              </Button.Cancel>
            </>
          )}

          {can_reconnect && (
            <Button.Save onClick={handleReconnect}>
              {t('Reconnect')}
            </Button.Save>
          )}

          {status === 'fail' && (
            <div className={styles.error}>
              <Icon id='repeat' />
              <span>{message}</span>
            </div>
          )}
        </div>
      </form>
    </SimpleBlock>
  )
}

export default AccountSettings
