//@flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type Node from 'react'

import Modal from '../../../../components/Modal'
import ModalBody from '../../../../components/Modal/ModalBody'
import ModalButtons from '../../../../components/Modal/ModalButtons'
import ModalCloseButton from '../../../../components/Modal/ModalCloseButton'
import ModalHead from '../../../../components/Modal/ModalHead'
import ModalRow from '../../../../components/Modal/ModalRow'
import ModalText from '../../../../components/Modal/ModalText'
import Radio from '../../../../components/Radio'
import Button from '../../../../components/Button'
import Warning from '../../../../components/Warning'

import { useOverflow, useThemeColor } from '../../../../hooks'

import styles from './RequestCategoryConfirm.module.scss'

type Props = {
  isOpen: boolean,
  onClose: () => void,
  onConfirm: boolean => void,
}

const RequestCategoryConfirm = (props: Props): Node => {
  const { t } = useTranslation('Request')
  useOverflow()
  const themeColor = useThemeColor()
  const [switchPipeline, setSwitchPipeline] = useState(false)
  const handleChangeSwitch = e => setSwitchPipeline(JSON.parse(e.target.value))

  const handleConfirm = () => {
    props.onConfirm(switchPipeline)
    props.onClose()
  }

  return (
    <>
      <Modal isOpen={props.isOpen} onRequestClose={props.onClose}>
        <ModalHead title={t('SwitchPipelineTitle')} />
        <ModalCloseButton onClose={props.onClose} />
        <ModalBody
          style={{
            '--themeColor': themeColor,
          }}
        >
          <ModalRow>
            <ModalText text={t('SwitchPipelineQuestion')} />
          </ModalRow>
          <ModalRow>
            <Radio
              id='not_switch'
              name='switch_pipeline'
              value={false}
              checked={!switchPipeline}
              label={t('NoSwitchPipeline')}
              onChange={handleChangeSwitch}
            />
          </ModalRow>
          <ModalRow>
            <Radio
              id='switch'
              name='switch_pipeline'
              value={true}
              checked={switchPipeline}
              label={t('SwitchPipeline')}
              onChange={handleChangeSwitch}
            />
          </ModalRow>
          <ModalRow>
            <Warning
              noArrow
              text={t('PipelineFilesWarning')}
              boxClassName={styles.warningBox}
              className={styles.warning}
            />
          </ModalRow>
        </ModalBody>
        <ModalButtons>
          <Button.Save onClick={handleConfirm}>{t('Confirm')}</Button.Save>
          <Button.Cancel onClick={props.onClose}>
            {t('Common:Cancel')}
          </Button.Cancel>
        </ModalButtons>
      </Modal>
    </>
  )
}

export default RequestCategoryConfirm
